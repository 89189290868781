
export const parseCharacterBible = (characterBibleContent) => {
  const characterBible = {};

  const strategies = [
    (content) => content.split(/\n(?=\d+\.\s)/),
    (content) => content.split('\n\n'),
    (content) => content.split(/\n\nCharacter \d+:\s/).filter(entry => entry.trim() !== '')
  ];

  for (const strategy of strategies) {
    const characterEntries = strategy(characterBibleContent);

    characterEntries.forEach(entry => {
      const lines = entry.split('\n');
      const nameLine = lines[0].trim();

      let name = '';
      let details = '';

      if (strategy === strategies[0]) {
        const nameMatch = nameLine.match(/\d+\.\s*(.+):/);
        if (nameMatch && lines.length > 1) {
          name = nameMatch[1].trim();
          details = lines.slice(1).join(' ').trim();
        }
      } else if (strategy === strategies[1]) {
        if (nameLine.endsWith(':')) {
          name = nameLine.slice(0, -1).trim();
          details = lines.slice(1).join(' ').trim();
        }
      } else if (strategy === strategies[2]) {
        [name] = nameLine.split(':');
        details = lines.slice(1).join(' ').trim();
      }

      if (name && details) {
        characterBible[name] = details;
      }
    });

    if (Object.keys(characterBible).length > 0) {
      return characterBible;
    }
  }

  return characterBible;
};

/*export const formatCharacterBible = (characters, characterBible) => {
  return characters.map(name => {
    const details = characterBible[name.replace(/[*-]/g, '').trim()];
    return `${name}: ${details || 'No details available'}`;
  }).join(', ');
};*/

export const formatCharacterBible = (characters, characterBible, options = {}) => {
  const { includeFields = null, excludeFields = [] } = options;

  return characters.map(characterName => {
    const details = characterBible[characterName];
    if (details) {
      // Filter fields based on options
      const detailsString = Object.entries(details)
        .filter(([key]) =>
          (!includeFields || includeFields.includes(key)) &&
          !excludeFields.includes(key)
        )
        .map(([key, value]) => {
          if (typeof value === 'object' && value !== null) {
            return `${key}: { ${Object.entries(value).map(([subKey, subValue]) => `${subKey}: ${subValue}`).join(', ')} }`;
          }
          return `${key}: ${value}`;
        })
        .join(', ');

      return `${characterName}: ${detailsString}`;
    }
    return `${characterName}: Details not found`;
  }).join(' | ');
};

export const formatCharacterDetails = (details) => {
  return Object.entries(details)
    .map(([key, value]) => `${key}: ${value}`)
    .join('\n');
};

export const formatCharacterBibleOnRegeneration = (characters, characterBible) => {
  return Object.entries(characters).map(([name, details]) => {
    const formattedName = name.replace(/[*-]/g, '').trim();
    const characterDetails = characterBible[formattedName] || 'No details available';
    return `${formattedName}: ${characterDetails}`;
  }).join(', ');
};

/* export const transformCharacterBibleForSlideshow = (characterBible) => {
  return Object.entries(characterBible).map(([name, details]) => {
    return {
      name: name,
      details: details.split('    ').reduce((acc, item) => {
        const [key, value] = item.split(':');
        if (key && value) {
          acc[key.trim()] = value.trim();
        }
        return acc;
      }, {})
    };
  });
}; */

export const transformCharacterBibleForSlideshow = (characterBible) => {
  return Object.entries(characterBible).reduce((acc, [name, details]) => {
    acc[name] = details.split('    ').reduce((detailsAcc, item) => {
      const [key, value] = item.split(':');
      if (key && value) {
        detailsAcc[key.trim()] = value.trim();
      }
      return detailsAcc;
    }, { name: name }); // Add the name as an attribute within the details object
    return acc;
  }, {});
};


export const transformCharacterBible = (characterBible) => {
  return Object.entries(characterBible).reduce((acc, [name, details]) => {
    const sections = details.split(/\s{2,}/).filter(section => section.trim());
    const detailsObj = { name: name };

    sections.forEach(section => {
      const [key, value] = section.split(/:\s*(.+)/);

      if (key && value) {
        const trimmedKey = key.trim();
        const trimmedValue = value.trim();

        // Check if the value contains nested details
        if (trimmedValue.includes('\n')) {
          const subFields = trimmedValue.split('\n').reduce((subAcc, subField) => {
            const [subKey, subValue] = subField.split(/:\s*(.+)/);
            if (subKey && subValue) {
              subAcc[subKey.trim()] = subValue.trim();
            }
            return subAcc;
          }, {});

          detailsObj[trimmedKey] = subFields;
        } else {
          detailsObj[trimmedKey] = trimmedValue;
        }
      } else if (key) {
        detailsObj[key.trim()] = '';
      }
    });

    acc[name] = detailsObj;
    return acc;
  }, {});
};



export const parseScenesFromDetailedScreenplay = (detailedScreenplay) => {
  const sceneRegex = /Scene (\d+):\s*Title: (.+)\s*Duration: (.+)\s*Visual Prompt: (.+?)\s*Setting Details: (.+?)\s*Environment: (.+?)\s*Characters and Expressions:\s*([\s\S]+?)\s*Dialogue Script:\s*([\s\S]+?)\s*Background Music: (.+?)\s*Character List: (.+)/g;
  const scenes = [];
  let match;
  while ((match = sceneRegex.exec(detailedScreenplay)) !== null) {
    scenes.push({
      number: match[1],
      title: match[2],
      duration: match[3],
      visualPrompt: match[4],
      settingDetails: match[5],
      environment: match[6],
      characterExperessions: match[7].split('\n').map(line => line.trim()).filter(line => line),
      dialogues: match[8].split('\n').map(line => line.trim()).filter(line => line),
      backgroundMusic: match[9],
      characters: match[10].split(',').map(character => character.trim()).filter(character => character)
    });
  }
  return scenes.slice(0, 15);
};

export const downloadToBlob = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch ${url}`);
  }
  return await response.blob();
};

export const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
  });
};

export const base64ToBlob = async (base64) => {
  const res = await fetch(base64);
  return await res.blob();
};

export const parseCharacterBible2 = (characterBibleString) => {
  const characterBible = {};

  // Split by character blocks
  const characterBlocks = characterBibleString.split(/\n(?=\d+\.\s)/);

  characterBlocks.forEach(block => {
    // Extract the character name and remove the number prefix
    const [nameLine, ...detailsLines] = block.split('\n');
    const nameMatch = nameLine.match(/\d+\.\s*(.+):/);

    if (nameMatch) {
      const name = nameMatch[1].trim();
      const detailsObj = { name };

      let currentCategory = null;

      detailsLines.forEach(line => {
        const trimmedLine = line.trim();

        // Check for a new category (e.g., Appearance, Attire, etc.)
        if (/^[A-Za-z\s]+:$/.test(trimmedLine)) {
          currentCategory = trimmedLine.slice(0, -1).trim();
        } else if (currentCategory && trimmedLine) {
          // For nested categories (like under Physical Dimensions or Demographic)
          const [key, ...valueParts] = trimmedLine.split(':');
          const value = valueParts.join(':').trim(); // Join back in case of additional colons

          if (!detailsObj[currentCategory]) {
            detailsObj[currentCategory] = {};
          }

          detailsObj[currentCategory][key.trim()] = value;
        }
      });

      characterBible[name] = detailsObj;
    }
  });

  return characterBible;
}

export const parseCharacterDescriptions = (characterBibleString) => {
  const characterMap = new Map();

  // Ensure the string is trimmed of leading and trailing whitespace
  const trimmedString = characterBibleString.trim();

  // Split the input string by characters that start with "1.", "2.", "3.", etc.
  let characterEntries = trimmedString.split(/\d+\.\s+/);

  // Remove any empty or malformed entries
  characterEntries = characterEntries.filter(entry => entry && entry.trim() !== "");

  characterEntries.forEach((entry, index) => {
    try {
      const lines = entry.split("\n").filter(line => line && line.trim() !== "");

      if (lines.length > 0) {
        const nameLine = lines[0].trim();
        const characterName = nameLine.substring(0, nameLine.length - 1); // Remove the trailing colon

        const characterDescription = lines.slice(1).join("\n");

        characterMap.set(characterName, {
          description: characterDescription
        });
      } else {
        console.warn(`Empty or malformed entry at index ${index}`);
      }
    } catch (error) {
      console.error(`Error processing entry at index ${index}:`, error);
    }
  });

  return characterMap;
};


export const generateCharacterDescriptions = (characters, characterBible) => {
  let result = '';

  characters.forEach(name => {
    if (characterBible.has(name)) {
      result += `${name}:\n${characterBible.get(name).description}\n\n`;
    }
  });

  return result.trim();
}

export const generateCharacterDescriptionsConcise = (characters, characterBible) => {
  let result = '';

  characters.forEach(name => {
    if (characterBible.has(name)) {
      result += `${name}:\n${characterBible.get(name).conciseDescription}\n\n`;
    }
  });

  return result.trim();
}