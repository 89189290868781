import React, { useState, useEffect, useRef } from 'react';
import './VideoTile.css';
import WaveSurfer from 'wavesurfer.js';
import thumbnail from './assets/image/thumbnail2.jpg'; // Import the thumbnail image

const VideoTile = ({ video, title, duration, summary, additionalInfo, onRegenerateVideo, onRegenerateVideoPostEdit }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [visualPrompt, setVisualPrompt] = useState(additionalInfo.visual);
  const [sceneVideo, setSceneVideo] = useState(video);
  const [audioUrls, setAudioUrls] = useState([]);
  const [isPlaying, setIsPlaying] = useState([]);
  const waveSurferInstances = useRef([]);

  // Function to sanitize the title for a valid CSS selector
  const sanitizeTitle = (title) => {
    return title.replace(/[^a-zA-Z0-9]/g, '-');
  };

  useEffect(() => {
    setSceneVideo(video);
  }, [video]);

  useEffect(() => {
    const urls = additionalInfo.dialogues.map(dialogue => dialogue.audio);
    setAudioUrls(urls);
  }, [additionalInfo.dialogues]);

  useEffect(() => {
    waveSurferInstances.current = audioUrls.map((url, index) => {
      const waveSurferInstance = WaveSurfer.create({
        container: `#waveform-${index}-${sanitizeTitle(title)}`, // Ensure unique container ID
        waveColor: '#ddd',
        progressColor: '#4CAF50',
        cursorColor: '#020202',
        height: 50,
        barWidth: 2,
      });
      waveSurferInstance.load(url);
      return waveSurferInstance;
    });
    setIsPlaying(Array(audioUrls.length).fill(false));

    return () => {
      waveSurferInstances.current.forEach(instance => {
        if (instance) {
          instance.destroy();
        }
      });
      waveSurferInstances.current = [];
    };
  }, [audioUrls, title]);

  const handleToggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const handleRegenerateVideo = async () => {
    onRegenerateVideo(visualPrompt);
  };

  const handleVisualPromptClick = () => {
    setIsEditing(true);
  };

  const handleVisualPromptChange = (e) => {
    setVisualPrompt(e.target.value);
  };

  const handleVisualPromptKeyDown = async (e) => {
    if (e.key === 'Enter') {
      setIsEditing(false);
      onRegenerateVideoPostEdit(visualPrompt);
    }
  };

  const togglePlayPause = (index) => {
    if (waveSurferInstances.current[index].isPlaying()) {
      waveSurferInstances.current[index].pause();
      setIsPlaying(prev => prev.map((play, i) => (i === index ? false : play)));
    } else {
      waveSurferInstances.current[index].play();
      setIsPlaying(prev => prev.map((play, i) => (i === index ? true : play)));
    }
  };

  return (
    <div className="video-tile">
      <div className="title-bar">
        <h3>{title}</h3>
        <button className="regenerate-button" onClick={handleRegenerateVideo}>⟳</button>
      </div>
      <video src={sceneVideo} poster={thumbnail} controls className="scene-video" />
      <div className="scene-overview">
        <p>{summary}</p>
      </div>
      <div className="video-additional-info">
        <div className="media-notes-container">
          <p><strong>Dialogues:</strong></p>
          <ul className="audio-list">
            {additionalInfo.dialogues.map((dialogue, index) => (
              <li key={index}>
                <div className="waveform-container">
                  <div className="waveform-buttons-container">
                    <button onClick={() => togglePlayPause(index)} className="waveform-play-pause-button">{isPlaying[index] ? '❚❚' : '▶'}</button>
                    <button onClick={() => togglePlayPause(index)} className="waveform-download-button">⤓</button>
                    <div id={`waveform-${index}-${sanitizeTitle(title)}`} className="waveform"></div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <p><strong>Background Score:</strong></p><br />
          <div className="audio-container">
            <audio controls>
              <source src={additionalInfo.music.audio} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        </div>
        <p><strong>Scene Notes:</strong></p>
        <textarea className="notes-textarea" defaultValue="Enter Notes..."></textarea>
      </div>
    </div>
  );
};

export default VideoTile;
