import axios from 'axios';

const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

export const generateDetailedScreenplay = async (title, description) => {
  try {
    console.log("Title:", title.title);
    console.log("Description:", title.description);

    const prompt = `Create a detailed screenplay for the story titled "${title.title}". The plot of the story is "${title.description}". The screenplay should be structured as follows:

    1. Title: Provide the title of the story.
    2. Character Bible: List all the characters in the story. For each character, include:
       - Appearance: Physical description, attire.
       - Personality: Traits, behavior.
    3. Scenes: Break down the screenplay scene by scene. For each scene, include:
       - Scene Number and Title: Sequential scene number and an appropriate name.
       - Duration: Estimated duration of the scene.
       - Visual Prompt: Description to generate the visual elements of the scene.
       - Dialogue Script: Conversations between characters.
       - Background Music: Description of the background music to set the mood.

    Here is an example format to follow:

    Title: ${title}

    Character Bible:
    - Character1:
      - Appearance: Description
      - Personality: Description
    - Character2:
      - Appearance: Description
      - Personality: Description

    Scene 1:
    - Title: Scene Title
    - Duration: X mins
    - Visual Prompt: "Description of the scene"
    - Environment: "Environment and architectural details for the scene"
    - Characters and Expressions:
      - Character1: "Character expressions, emotions, attire and other important details for character 1"
      - Character2: "Character expressions, emotions, attire and other important details for character 2"
    - Dialogue Script:
      - Character1: "Dialogue line"
      - Character2: "Dialogue line"
    - Background Music: Description of the background music

    Scene 2:
    - Title: Scene Title
    - Duration: X mins
    - Visual Prompt: "Description of the scene"
    - Environment: "Environment and architectural details for the scene"
    - Characters and Expressions:
      - Character1: "Character expressions, emotions, attire and other important details for character 1"
      - Character2: "Character expressions, emotions, attire and other important details for character 2"
    - Dialogue Script:
      - Character1: "Dialogue line"
      - Character2: "Dialogue line"
    - Background Music: Description of the background music

    ...and so on for the rest of the scenes. Ensure that your response includes all scenes starting from scene 1. The title followed by character bible needs to be on top.
  `;

    const response = await axios.post(
      'https://api.openai.com/v1/completions',
      {
        model: 'gpt-3.5-turbo-instruct',
        prompt,
        max_tokens: 3000,
        n: 1,
        stop: null,
        temperature: 0.2,
      },
      {
        headers: {
          Authorization: `Bearer ${API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log("Response:", response.data);

    return response.data.choices[0].text;
  } catch (error) {
    console.error('Error generating response:', error.response ? error.response.data : error.message);
  }
};

export const generateDetailedScreenplayUpgrade4 = async (title, description) => {
  try {
    console.log("Title:", title.title);
    console.log("Description:", title.description);

    const messages = [
      {
        role: "user",
        content: `Create a detailed screenplay for the story titled "${title.title}". The plot of the story is "${title.description}". The screenplay should be structured as follows:

    1. Title: Provide the title of the story.
    2. Character Bible: List all the characters in the story. For each character, include:
       - Appearance: Detailed physical description including height, build, facial features (eye color, nose shape, lip shape, etc.), hair color and style, skin tone, and attire. Ensure these details are consistent and emphasized for continuity.
       - Physical Dimensions: Specific measurements such as height, weight, and body build.
       - Distinctive Features: Any distinctive marks (scars, tattoos, etc.).
       - Personality: Traits, behavior, and any distinctive mannerisms.
       - Key Traits: Recurring elements or attributes that should be consistent across scenes.
       - Demographic: Age, gender, ethinicity, race, religion, nationality.
    3. Cinematic Sequence: Chronological break down of the screenplay scene by scene. For each scene, include:
       - Scene Number and Title: Sequential scene number and an appropriate name.
       - Duration: Estimated duration of the scene.
       - Visual Prompt: Description to generate the visual elements of the scene.
       - Environment: "Environment and architectural details for the scene. Time in historical chronology this occurs"
       - Characters and Expressions: Character expressions, emotions, attire and other important details for character.
       - Dialogue Script: Conversations between characters. Include the emotion of the characters.
       - Background Music: Description of the background music to set the mood.

    Here is an example format to follow:

    Title: ${title.title}

    Character Bible:
    - Character1:
      - Appearance: Description of facial features, height, build, hair color and style, skin tone, and attire.
      - Physical Dimensions: Specific measurements such as height, weight, and body build.
      - Distinctive Features: Any distinctive marks (scars, tattoos, etc.).
      - Personality: Description of traits, behavior, and distinctive mannerisms.
      - Key Traits: Recurring elements or attributes that should be consistent across scenes.
      - Demographic: Age, gender, ethinicity, race, religion, nationality.
    - Character2:
      - Appearance: Description of facial features, height, build, hair color and style, skin tone, and attire.
      - Physical Dimensions: Specific measurements such as height, weight, and body build.
      - Distinctive Features: Any distinctive marks (scars, tattoos, etc.).
      - Personality: Description of traits, behavior, and distinctive mannerisms.
      - Key Traits: Recurring elements or attributes that should be consistent across scenes.
      - Demographic: Age, gender, ethinicity, race, religion, nationality.

    Cinematic Sequence:
    Scene 1:
    - Title: Scene Title
    - Duration: X mins
    - Visual Prompt: "Description of the scene"
    - Environment: "Environment and architectural details for the scene. Time in historical chronology this occurs"
    - Characters and Expressions:
      - Character1: "Character expressions, emotions, attire and other important details for character 1"
      - Character2: "Character expressions, emotions, attire and other important details for character 2"
    - Dialogue Script:
      - Character1: "(Emotion) Dialogue line"
      - Character2: "(Emotion) Dialogue line"
    - Background Music: Description of the background music

    Scene 2:
    - Title: Scene Title
    - Duration: X mins
    - Visual Prompt: "Description of the scene"
    - Environment: "Environment and architectural details for the scene. Time in historical chronology this occurs"
    - Characters and Expressions:
      - Character1: "Character expressions, emotions, attire and other important details for character 1"
      - Character2: "Character expressions, emotions, attire and other important details for character 2"
    - Dialogue Script:
      - Character1: "(Emotion) Dialogue line"
      - Character2: "(Emotion) Dialogue line"
    - Background Music: Description of the background music

    ...and so on for the rest of the scenes. Ensure that the visual prompt is as detailed as possible so that it can be used as a prompt to generate the video from SORA AI. Ensure that your response includes all scenes starting from scene 1. The title followed by character bible needs to be on top.`
      }
    ];

    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o',
        messages,
        max_tokens: 3000,
        temperature: 0.2,
      },
      {
        headers: {
          Authorization: `Bearer ${API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log("Response:", response.data);

    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error generating response:', error.response ? error.response.data : error.message);
  }
};

export const generateDetailedScreenplayUpgrade4o = async (title, description) => {
  try {
    console.log("Title:", title.title);
    console.log("Description:", title.description);

    const messages = [
      {
        role: "user",
        content: `Create a detailed screenplay for the story titled "${title.title}". The plot of the story is "${title.description}". The screenplay should be structured as follows:

    1. Title: Provide the title of the story.
    2. Character Bible: List all the characters in the story. For each character, include:
        Appearance: Detailed physical description including height, build, facial features (eye color, nose shape, lip shape, etc.), hair color and style, skin tone, and attire. Ensure these details are consistent and emphasized for continuity.
        Physical Dimensions: Specific measurements such as height, weight, and body build.
        Personality: Traits, behavior, and any distinctive mannerisms.
        Key Traits: Recurring elements or attributes that should be consistent across scenes.
        Demographic: Age, gender, ethnicity, race(be very specific.. eg: south asian indian, white caucasian scandinavian etc), religion, nationality.
        Facial Landmarks: Position of eyes, nose, mouth, and chin. Distances and angles between facial features.
        Geometric Features: Shape of the jawline, cheekbone structure, forehead shape.
        Texture and Skin Tone: Skin color and texture patterns, presence of freckles, moles, or other skin markings.
        Facial Proportions: Width and height of the face, ratios between different parts of the face (e.g., distance between the eyes, nose length).
        Distinctive Features: Scars, birthmarks, or tattoos on the face, wrinkles and age-related changes.
        Eye Features: Color and shape of the eyes, eyebrow shape and positioning.
        Lips and Ears: Shape and size of the lips, Color and texture of the lips, Shape and size of the ears.
        Hair Characteristics: Hairline shape and position, facial hair patterns and style(beard/moustache).
        Expressions: Range of common facial expressions and how they affect facial features, demeanor etc.
        3D Face Shape: Depth and contour of the face, 3D model of the face's structure.
    3. Cinematic Sequence: Chronological break down of the screenplay scene by scene. For each scene, include:
        Scene Number: 
        Title: Appropriate title for the scene.
        Duration: Estimated duration of the scene.
        Visual Prompt: Description to generate the visual elements of the scene(include all the details).
        Setting Details: Include elements like props, furniture, vehicles, or specific items that should be prominent. Mention the layout and positioning of objects within the scene.
        Environment: "Environment and architectural details for the scene. Time of the day and weather. Point in historical chronology this event occurs (century/era, ancient/modern etc)"
        Characters and Expressions: Character expressions, emotions, attire and other important details for character.
        Dialogue Script: Conversations between characters. Include the emotion of the characters.
        Background Music: Description of the background music to set the mood.
        Character List: List of characters involved in this scene.

    Here is an example format to follow:

    Title: ${title.title}

    Character Bible:
     1. Character1:
       Appearance: Description of facial features, height, build, hair color and style, skin tone, and attire.
       Physical Dimensions: Specific measurements such as height, weight, and body build.
       Personality: Description of traits, behavior, and distinctive mannerisms.
       Key Traits: Recurring elements or attributes that should be consistent across scenes.
       Demographic: Age, gender, ethnicity, race(be very specific.. eg: south asian indian, white caucasian scandinavian etc), religion, nationality.
       Facial Landmarks: Position of eyes, nose, mouth, and chin. Distances and angles between facial features.
       Geometric Features: Shape of the jawline, cheekbone structure, forehead shape.
       Texture and Skin Tone: Skin color and texture patterns, presence of freckles, moles, or other skin markings.
       Facial Proportions: Width and height of the face, ratios between different parts of the face (e.g., distance between the eyes, nose length).
       Distinctive Features: Scars, birthmarks, or tattoos on the face, wrinkles and age-related changes.
       Eye Features: Color and shape of the eyes, eyebrow shape and positioning.
       Lips and Ears: Shape and size of the lips, Color and texture of the lips, Shape and size of the ears.
       Hair Characteristics: Hairline shape and position, facial hair patterns and style(beard/moustache).
       Expressions: Range of common facial expressions and how they affect facial features, demeanor etc.
       3D Face Shape: Depth and contour of the face, 3D model of the face's structure.
     2. Character2:
       Appearance: Description of facial features, height, build, hair color and style, skin tone, and attire.
       Physical Dimensions: Specific measurements such as height, weight, and body build.
       Personality: Description of traits, behavior, and distinctive mannerisms.
       Key Traits: Recurring elements or attributes that should be consistent across scenes.
       Demographic: Age, gender, ethnicity, race(be very specific.. eg: south asian indian, white caucasian scandinavian etc), religion, nationality.
       Facial Landmarks: Position of eyes, nose, mouth, and chin. Distances and angles between facial features.
       Geometric Features: Shape of the jawline, cheekbone structure, forehead shape.
       Texture and Skin Tone: Skin color and texture patterns, presence of freckles, moles, or other skin markings.
       Facial Proportions: Width and height of the face, ratios between different parts of the face (e.g., distance between the eyes, nose length).
       Distinctive Features: Scars, birthmarks, or tattoos on the face, wrinkles and age-related changes.
       Eye Features: Color and shape of the eyes, eyebrow shape and positioning.
       Lips and Ears: Shape and size of the lips, Color and texture of the lips, Shape and size of the ears.
       Hair Characteristics: Hairline shape and position, facial hair patterns and style(beard/moustache).
       Expressions: Range of common facial expressions and how they affect facial features, demeanor etc.
       3D Face Shape: Depth and contour of the face, 3D model of the face's structure.

    Cinematic Sequence:
    Scene 1:
     Title: Scene Title
     Duration: X mins
     Visual Prompt: Description of the scene
     Setting Details: Include elements like props, furniture, vehicles, or specific items that should be prominent. Mention the layout and positioning of objects within the scene.
     Environment: Environment and architectural details for the scene. Time of the day and weather. Point in historical chronology this event occurs (century/era, ancient/modern etc)
     Characters and Expressions:
       Character1: Character expressions, emotions, attire and other important details for character 1
       Character2: Character expressions, emotions, attire and other important details for character 2
     Dialogue Script:
       Character1: "(Emotion) Dialogue line"
       Character2: "(Emotion) Dialogue line"
     Background Music: Description of the background music
     Character List: Character1, Character2

    Scene 2:
     Title: Scene Title
     Duration: X mins
     Visual Prompt: Description of the scene
     Setting Details: Include elements like props, furniture, vehicles, or specific items that should be prominent. Mention the layout and positioning of objects within the scene.
     Environment: Environment and architectural details for the scene. Time of the day and weather. Point in historical chronology this event occurs (century/era, ancient/modern etc)
     Characters and Expressions:
       Character1: Character expressions, emotions, attire and other important details for character 1
       Character2: Character expressions, emotions, attire and other important details for character 2
     Dialogue Script:
       Character1: "(Emotion) Dialogue line"
       Character2: "(Emotion) Dialogue line"
     Background Music: Description of the background music
     Character List: Character1, Character2

    ...and so on for the rest of the scenes. Ensure that the visual prompt is as detailed as possible so that it can be used as a prompt to generate the video from SORA AI. Ensure that your response includes all scenes starting from scene 1. The title followed by character bible needs to be on top.`
      }
    ];

    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-2024-08-06',
        messages,
        max_tokens: 10000,
        temperature: 0.2,
      },
      {
        headers: {
          Authorization: `Bearer ${API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log("Response:", response.data);

    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error generating response:', error.response ? error.response.data : error.message);
  }
};


export const generateDetailedScreenplayUpgrade4o1 = async (title, description) => {
  try {
    console.log("Title:", title.title);
    console.log("Description:", title.description);

    const messages = [
      {
        role: "user",
        content: `Create a detailed screenplay for the story titled "${title.title}". The plot of the story is "${title.description}". The screenplay should be structured as follows:

    1. Title: Provide the title of the story.
    2. Character Bible: List all the characters in the story. For each character, include:
        Appearance: Detailed physical description of the character. Ensure these details are consistent and emphasized for continuity.
        Attire: Clothing style, color, and any distinctive accessories.
        Physical Dimensions:
          Height: [Insert Height] Weight: [Insert Weight] Build: [Slim, muscular, average, etc.]
        Personality:
          Traits: Description of core personality traits, behaviors, and distinctive mannerisms.
          Key Traits: Recurring elements or attributes that should be consistent across scenes.
        Demographic:
          Age: [Insert Age] Gender: [Insert Gender] Ethnicity: [e.g., South Asian Indian, White Caucasian Scandinavian, etc.] Race: [Insert Race] Religion: [Insert Religion] Nationality: [Insert Nationality]
        Facial Landmarks:
          Eye Position: Precise distance and angles between the eyes.
          Nose Position: Length, width, and placement of the nose relative to the eyes and mouth.
          Mouth Position: Width of the mouth, thickness of lips, and the distance between the nose and mouth.
          Chin Position: Placement and contour of the chin.
        Geometric Features:
          Jawline: Shape, width, and angle of the jawline.
          Cheekbones: Structure, height, and prominence.
          Forehead: Shape and height.
          Chin: Contour and sharpness, including the jawline’s width and angle.
        Texture and Skin Tone:
          Skin Color: [Insert Skin Color]
          Texture Patterns: Presence of freckles, moles, scars, or other skin markings.
          Wrinkles & Age-related Changes: Specific details on any wrinkles or age-related features.
        Facial Proportions:
          Face Width & Height: Specific measurements of the face.
          Ratios: Detailed ratios between different parts of the face (e.g., distance between eyes, nose length, etc.).
        Distinctive Features:
          Scars: [Insert Scars] Birthmarks: [Insert Birthmarks] Tattoos: [Insert Tattoos] Wrinkles & Age-related Changes: Specific details on any wrinkles or age-related features.
        Eye Features:
          Color: [Insert Eye Color]
          Shape: [Insert Eye Shape]
          Eyebrow Shape: Arch, thickness, and placement of eyebrows relative to the eyes.
          Space Between Eyes: Precise measurement of the distance between the two eyes.
        Nose, Lips, and Ears:
          Nose: Width, length, and curvature.
          Lips: Width, thickness, color, and texture.
          Ears: Shape, size, unique curves, edges, and the angle at which the ears sit relative to the face.
        Hair Characteristics:
          Hairline: Shape and position.
          Hair Texture: Texture, thickness, length and density.
          Facial Hair: Patterns, thickness, texture, density, length, and style of beard and mustache.
        Expressions:
          Common Expressions: Range of facial expressions and how they affect facial features and demeanor.
        3D Face Shape:
          Depth & Contour: Detailed description of the depth and contour of the face.
        3D Model: 
          Detailed model of the face’s structure, including the depth of the nose bridge and recession of the eyes.
        Dynamic Features:
          Emotion-based Changes: How the face changes with different emotions, including the speed and frequency of blinking.
    3. Cinematic Sequence: Chronological break down of the screenplay scene by scene. For each scene, include:
        Scene Number: 
        Title: Appropriate title for the scene.
        Duration: Estimated duration of the scene.
        Visual Prompt: Detailed description to generate the visual elements of the scene(include all the details).
        Setting Details: Include elements like props, furniture, vehicles, or specific items that should be prominent. Mention the layout and positioning of objects within the scene.
        Environment: Environment and architectural details for the scene. Time of the day and weather. Point in historical chronology this event occurs (century/era, ancient/modern etc)
        Characters and Expressions: Character expressions, emotions, attire and other important details for character.
        Dialogue Script: Conversations between characters. Include the emotion of the characters.
        Background Music: Description of the background music to set the mood.
        Character List: List of characters involved in this scene.

    Here is an example format to follow:

    Title: ${title.title}

    Character Bible:
     1. Character1:
          Appearance: Detailed physical description of the character. Ensure these details are consistent and emphasized for continuity.
          Attire: Clothing style, color, and any distinctive accessories.
          Physical Dimensions:
            Height: [Insert Height] Weight: [Insert Weight] Build: [Slim, muscular, average, etc.]
          Personality:
            Traits: Description of core personality traits, behaviors, and distinctive mannerisms.
            Key Traits: Recurring elements or attributes that should be consistent across scenes.
          Demographic:
            Age: [Insert Age] Gender: [Insert Gender] Ethnicity: [e.g., South Asian Indian, White Caucasian Scandinavian, etc.] Race: [Insert Race] Religion: [Insert Religion] Nationality: [Insert Nationality]
          Facial Landmarks:
            Eye Position: Precise distance and angles between the eyes.
            Nose Position: Length, width, and placement of the nose relative to the eyes and mouth.
            Mouth Position: Width of the mouth, thickness of lips, and the distance between the nose and mouth.
            Chin Position: Placement and contour of the chin.
          Geometric Features:
            Jawline: Shape, width, and angle of the jawline.
            Cheekbones: Structure, height, and prominence.
            Forehead: Shape and height.
            Chin: Contour and sharpness, including the jawline’s width and angle.
          Texture and Skin Tone:
            Skin Color: [Insert Skin Color]
            Texture Patterns: Presence of freckles, moles, scars, or other skin markings.
            Wrinkles & Age-related Changes: Specific details on any wrinkles or age-related features.
          Facial Proportions:
            Face Width & Height: Specific measurements of the face.
            Ratios: Detailed ratios between different parts of the face (e.g., distance between eyes, nose length, etc.).
          Distinctive Features:
            Scars: [Insert Scars] Birthmarks: [Insert Birthmarks] Tattoos: [Insert Tattoos] Wrinkles & Age-related Changes: Specific details on any wrinkles or age-related features.
          Eye Features:
            Color: [Insert Eye Color]
            Shape: [Insert Eye Shape]
            Eyebrow Shape: Arch, thickness, and placement of eyebrows relative to the eyes.
            Space Between Eyes: Precise measurement of the distance between the two eyes.
          Nose, Lips, and Ears:
            Nose: Width, length, and curvature.
            Lips: Width, thickness, color, and texture.
            Ears: Shape, size, unique curves, edges, and the angle at which the ears sit relative to the face.
          Hair Characteristics:
            Hairline: Shape and position.
            Hair Texture: Texture, thickness, length and density.
            Facial Hair: Patterns, thickness, texture, density, length, and style of beard and mustache.
          Expressions:
            Common Expressions: Range of facial expressions and how they affect facial features and demeanor.
          3D Face Shape:
            Depth & Contour: Detailed description of the depth and contour of the face.
          3D Model: 
            Detailed model of the face’s structure, including the depth of the nose bridge and recession of the eyes.
          Dynamic Features:
            Emotion-based Changes: How the face changes with different emotions, including the speed and frequency of blinking.
     2. Character2:
          Appearance: Detailed physical description of the character. Ensure these details are consistent and emphasized for continuity.
          Attire: Clothing style, color, and any distinctive accessories.
          Physical Dimensions:
            Height: [Insert Height] Weight: [Insert Weight] Build: [Slim, muscular, average, etc.]
          Personality:
            Traits: Description of core personality traits, behaviors, and distinctive mannerisms.
            Key Traits: Recurring elements or attributes that should be consistent across scenes.
          Demographic:
            Age: [Insert Age] Gender: [Insert Gender] Ethnicity: [e.g., South Asian Indian, White Caucasian Scandinavian, etc.] Race: [Insert Race] Religion: [Insert Religion] Nationality: [Insert Nationality]
          Facial Landmarks:
            Eye Position: Precise distance and angles between the eyes.
            Nose Position: Length, width, and placement of the nose relative to the eyes and mouth.
            Mouth Position: Width of the mouth, thickness of lips, and the distance between the nose and mouth.
            Chin Position: Placement and contour of the chin.
          Geometric Features:
            Jawline: Shape, width, and angle of the jawline.
            Cheekbones: Structure, height, and prominence.
            Forehead: Shape and height.
            Chin: Contour and sharpness, including the jawline’s width and angle.
          Texture and Skin Tone:
            Skin Color: [Insert Skin Color]
            Texture Patterns: Presence of freckles, moles, scars, or other skin markings.
            Wrinkles & Age-related Changes: Specific details on any wrinkles or age-related features.
          Facial Proportions:
            Face Width & Height: Specific measurements of the face.
            Ratios: Detailed ratios between different parts of the face (e.g., distance between eyes, nose length, etc.).
          Distinctive Features:
            Scars: [Insert Scars] Birthmarks: [Insert Birthmarks] Tattoos: [Insert Tattoos] Wrinkles & Age-related Changes: Specific details on any wrinkles or age-related features.
          Eye Features:
            Color: [Insert Eye Color]
            Shape: [Insert Eye Shape]
            Eyebrow Shape: Arch, thickness, and placement of eyebrows relative to the eyes.
            Space Between Eyes: Precise measurement of the distance between the two eyes.
          Nose, Lips, and Ears:
            Nose: Width, length, and curvature.
            Lips: Width, thickness, color, and texture.
            Ears: Shape, size, unique curves, edges, and the angle at which the ears sit relative to the face.
          Hair Characteristics:
            Hairline: Shape and position.
            Hair Texture: Texture, thickness, length and density.
            Facial Hair: Patterns, thickness, texture, density, length, and style of beard and mustache.
          Expressions:
            Common Expressions: Range of facial expressions and how they affect facial features and demeanor.
          3D Face Shape:
            Depth & Contour: Detailed description of the depth and contour of the face.
          3D Model: 
            Detailed model of the face’s structure, including the depth of the nose bridge and recession of the eyes.
          Dynamic Features:
            Emotion-based Changes: How the face changes with different emotions, including the speed and frequency of blinking.

    Cinematic Sequence:
    Scene 1:
     Title: Scene Title
     Duration: X mins
     Visual Prompt: Deatiled description of the scene
     Setting Details: Include elements like props, furniture, vehicles, or specific items that should be prominent. Mention the layout and positioning of objects within the scene.
     Environment: Environment and architectural details for the scene. Time of the day and weather. Point in historical chronology this event occurs (century/era, ancient/modern etc)
     Characters and Expressions:
       Character1: Character expressions, emotions, attire and other important details for character 1
       Character2: Character expressions, emotions, attire and other important details for character 2
     Dialogue Script:
       Character1: "(Emotion) Dialogue line"
       Character2: "(Emotion) Dialogue line"
     Background Music: Description of the background music
     Character List: Character1, Character2

    Scene 2:
     Title: Scene Title
     Duration: X mins
     Visual Prompt: Deatiled description of the scene
     Setting Details: Include elements like props, furniture, vehicles, or specific items that should be prominent. Mention the layout and positioning of objects within the scene.
     Environment: Environment and architectural details for the scene. Time of the day and weather. Point in historical chronology this event occurs (century/era, ancient/modern etc)
     Characters and Expressions:
       Character1: Character expressions, emotions, attire and other important details for character 1
       Character2: Character expressions, emotions, attire and other important details for character 2
     Dialogue Script:
       Character1: "(Emotion) Dialogue line"
       Character2: "(Emotion) Dialogue line"
     Background Music: Description of the background music
     Character List: Character1, Character2

    ...and so on for the rest of the scenes. Ensure that the visual prompt is as detailed as possible so that it can be used as a prompt to generate the video from SORA AI. Ensure that your response includes all scenes starting from scene 1. The title followed by character bible needs to be on top.`
      }
    ];

    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-2024-08-06',
        messages,
        max_tokens: 10000,
        temperature: 0.2,
      },
      {
        headers: {
          Authorization: `Bearer ${API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log("Response:", response.data);

    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error generating response:', error.response ? error.response.data : error.message);
  }
};

export const generateScreenplay = async (title, description) => {
  try {
//    console.log("Title:", title.title);
//    console.log("Description:", title.description);

    const messages = [
      {
        role: "user",
        content: `Create a detailed screenplay for the story titled "${title.title}". The plot of the story is "${title.description}". The screenplay should be structured as follows:

    1. Title: Provide the title of the story.
    2. Cinematic Sequence: Chronological break down of the screenplay scene by scene. For each scene, include:
        Scene Number: 
        Title: Appropriate title for the scene.
        Duration: Estimated duration of the scene.
        Visual Prompt: Detailed description to generate the visual elements of the scene(include all the details).
        Setting Details: Include elements like props, furniture, vehicles, or specific items that should be prominent. Mention the layout and positioning of objects within the scene.
        Environment: Environment and architectural details for the scene. Time of the day and weather. Point in historical chronology this event occurs (century/era, ancient/modern etc)
        Characters and Expressions: Character expressions, emotions, attire and other important details for character.
        Dialogue Script: Conversations between characters. Include the emotion of the characters.
        Background Music: Description of the background music to set the mood.
        Character List: List of characters involved in this scene.

    Here is an example format to follow:

    Title: ${title.title}

    Cinematic Sequence:
    Scene 1:
     Title: Scene Title
     Duration: X mins
     Visual Prompt: Deatiled description of the scene
     Setting Details: Include elements like props, furniture, vehicles, or specific items that should be prominent. Mention the layout and positioning of objects within the scene.
     Environment: Environment and architectural details for the scene. Time of the day and weather. Point in historical chronology this event occurs (century/era, ancient/modern etc)
     Characters and Expressions:
       Character1: Character expressions, emotions, attire and other important details for character 1
       Character2: Character expressions, emotions, attire and other important details for character 2
     Dialogue Script:
       Character1: "(Emotion) Dialogue line"
       Character2: "(Emotion) Dialogue line"
     Background Music: Description of the background music
     Character List: Character1, Character2

    Scene 2:
     Title: Scene Title
     Duration: X mins
     Visual Prompt: Deatiled description of the scene
     Setting Details: Include elements like props, furniture, vehicles, or specific items that should be prominent. Mention the layout and positioning of objects within the scene.
     Environment: Environment and architectural details for the scene. Time of the day and weather. Point in historical chronology this event occurs (century/era, ancient/modern etc)
     Characters and Expressions:
       Character1: Character expressions, emotions, attire and other important details for character 1
       Character2: Character expressions, emotions, attire and other important details for character 2
     Dialogue Script:
       Character1: "(Emotion) Dialogue line"
       Character2: "(Emotion) Dialogue line"
     Background Music: Description of the background music
     Character List: Character1, Character2

    ...and so on for the rest of the scenes. Ensure that the visual prompt is as detailed as possible so that it can be used as a prompt to generate the video from SORA AI. Ensure that your response includes all scenes starting from scene 1. The title needs to be on top.`
      }
    ];

    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-2024-08-06',
        messages,
        max_tokens: 10000,
        temperature: 0.2,
      },
      {
        headers: {
          Authorization: `Bearer ${API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log("Response:", response.data);

    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error generating response:', error.response ? error.response.data : error.message);
  }
};

export const generateCharacterBible = async (screenPlay) => {
  try {
    console.log("Screenplay:", screenPlay);

    const messages = [
      {
        role: "user",
        content: `Create a detailed character bible for the screenplay "${screenPlay}". The character bible should be structured as follows:

     Character Bible: List all the characters in the story. For each character, include:
        Appearance: Detailed physical description of the character. Ensure these details are consistent and emphasized for continuity.
        Attire: Clothing style, color, and any distinctive accessories.
        Physical Dimensions:
          Height: [Insert Height] Weight: [Insert Weight] Build: [Slim, muscular, average, etc.]
        Personality:
          Traits: Description of core personality traits, behaviors, and distinctive mannerisms.
          Key Traits: Recurring elements or attributes that should be consistent across scenes.
        Demographic:
          Age: [Insert Age] Gender: [Insert Gender] Ethnicity: [e.g., South Asian Indian, White Caucasian Scandinavian, etc.] Race: [Insert Race] Religion: [Insert Religion] Nationality: [Insert Nationality]
        Facial Landmarks:
          Eye Position: Precise distance and angles between the eyes.
          Nose Position: Length, width, and placement of the nose relative to the eyes and mouth.
          Mouth Position: Width of the mouth, thickness of lips, and the distance between the nose and mouth.
          Chin Position: Placement and contour of the chin.
        Geometric Features:
          Jawline: Shape, width, and angle of the jawline.
          Cheekbones: Structure, height, and prominence.
          Forehead: Shape and height.
          Chin: Contour and sharpness, including the jawline’s width and angle.
        Texture and Skin Tone:
          Skin Color: [Insert Skin Color]
          Texture Patterns: Presence of freckles, moles, scars, or other skin markings.
          Wrinkles & Age-related Changes: Specific details on any wrinkles or age-related features.
        Facial Proportions:
          Face Width & Height: Specific measurements of the face.
          Ratios: Detailed ratios between different parts of the face (e.g., distance between eyes, nose length, etc.).
        Distinctive Features:
          Scars: [Insert Scars] Birthmarks: [Insert Birthmarks] Tattoos: [Insert Tattoos] Wrinkles & Age-related Changes: Specific details on any wrinkles or age-related features.
        Eye Features:
          Color: [Insert Eye Color]
          Shape: [Insert Eye Shape]
          Eyebrow Shape: Arch, thickness, and placement of eyebrows relative to the eyes.
          Space Between Eyes: Precise measurement of the distance between the two eyes.
        Nose, Lips, and Ears:
          Nose: Width, length, and curvature.
          Lips: Width, thickness, color, and texture.
          Ears: Shape, size, unique curves, edges, and the angle at which the ears sit relative to the face.
        Hair Characteristics:
          Hairline: Shape and position.
          Hair Texture: Texture, thickness, length and density.
          Facial Hair: Patterns, thickness, texture, density, length, and style of beard and mustache.
        Expressions:
          Common Expressions: Range of facial expressions and how they affect facial features and demeanor.
        3D Face Shape:
          Depth & Contour: Detailed description of the depth and contour of the face.
        3D Model: 
          Detailed model of the face’s structure, including the depth of the nose bridge and recession of the eyes.
        Dynamic Features:
          Emotion-based Changes: How the face changes with different emotions, including the speed and frequency of blinking.

    Here is an example format to follow:

    Character Bible:
     1. Character1:
          Appearance: Detailed physical description of the character. Ensure these details are consistent and emphasized for continuity.
          Attire: Clothing style, color, and any distinctive accessories.
          Physical Dimensions:
            Height: [Insert Height] Weight: [Insert Weight] Build: [Slim, muscular, average, etc.]
          Personality:
            Traits: Description of core personality traits, behaviors, and distinctive mannerisms.
            Key Traits: Recurring elements or attributes that should be consistent across scenes.
          Demographic:
            Age: [Insert Age] Gender: [Insert Gender] Ethnicity: [e.g., South Asian Indian, White Caucasian Scandinavian, etc.] Race: [Insert Race] Religion: [Insert Religion] Nationality: [Insert Nationality]
          Facial Landmarks:
            Eye Position: Precise distance and angles between the eyes.
            Nose Position: Length, width, and placement of the nose relative to the eyes and mouth.
            Mouth Position: Width of the mouth, thickness of lips, and the distance between the nose and mouth.
            Chin Position: Placement and contour of the chin.
          Geometric Features:
            Jawline: Shape, width, and angle of the jawline.
            Cheekbones: Structure, height, and prominence.
            Forehead: Shape and height.
            Chin: Contour and sharpness, including the jawline’s width and angle.
          Texture and Skin Tone:
            Skin Color: [Insert Skin Color]
            Texture Patterns: Presence of freckles, moles, scars, or other skin markings.
            Wrinkles & Age-related Changes: Specific details on any wrinkles or age-related features.
          Facial Proportions:
            Face Width & Height: Specific measurements of the face.
            Ratios: Detailed ratios between different parts of the face (e.g., distance between eyes, nose length, etc.).
          Distinctive Features:
            Scars: [Insert Scars] Birthmarks: [Insert Birthmarks] Tattoos: [Insert Tattoos] Wrinkles & Age-related Changes: Specific details on any wrinkles or age-related features.
          Eye Features:
            Color: [Insert Eye Color]
            Shape: [Insert Eye Shape]
            Eyebrow Shape: Arch, thickness, and placement of eyebrows relative to the eyes.
            Space Between Eyes: Precise measurement of the distance between the two eyes.
          Nose, Lips, and Ears:
            Nose: Width, length, and curvature.
            Lips: Width, thickness, color, and texture.
            Ears: Shape, size, unique curves, edges, and the angle at which the ears sit relative to the face.
          Hair Characteristics:
            Hairline: Shape and position.
            Hair Texture: Texture, thickness, length and density.
            Facial Hair: Patterns, thickness, texture, density, length, and style of beard and mustache.
          Expressions:
            Common Expressions: Range of facial expressions and how they affect facial features and demeanor.
          3D Face Shape:
            Depth & Contour: Detailed description of the depth and contour of the face.
          3D Model: 
            Detailed model of the face’s structure, including the depth of the nose bridge and recession of the eyes.
          Dynamic Features:
            Emotion-based Changes: How the face changes with different emotions, including the speed and frequency of blinking.
     2. Character2:
          Appearance: Detailed physical description of the character. Ensure these details are consistent and emphasized for continuity.
          Attire: Clothing style, color, and any distinctive accessories.
          Physical Dimensions:
            Height: [Insert Height] Weight: [Insert Weight] Build: [Slim, muscular, average, etc.]
          Personality:
            Traits: Description of core personality traits, behaviors, and distinctive mannerisms.
            Key Traits: Recurring elements or attributes that should be consistent across scenes.
          Demographic:
            Age: [Insert Age] Gender: [Insert Gender] Ethnicity: [e.g., South Asian Indian, White Caucasian Scandinavian, etc.] Race: [Insert Race] Religion: [Insert Religion] Nationality: [Insert Nationality]
          Facial Landmarks:
            Eye Position: Precise distance and angles between the eyes.
            Nose Position: Length, width, and placement of the nose relative to the eyes and mouth.
            Mouth Position: Width of the mouth, thickness of lips, and the distance between the nose and mouth.
            Chin Position: Placement and contour of the chin.
          Geometric Features:
            Jawline: Shape, width, and angle of the jawline.
            Cheekbones: Structure, height, and prominence.
            Forehead: Shape and height.
            Chin: Contour and sharpness, including the jawline’s width and angle.
          Texture and Skin Tone:
            Skin Color: [Insert Skin Color]
            Texture Patterns: Presence of freckles, moles, scars, or other skin markings.
            Wrinkles & Age-related Changes: Specific details on any wrinkles or age-related features.
          Facial Proportions:
            Face Width & Height: Specific measurements of the face.
            Ratios: Detailed ratios between different parts of the face (e.g., distance between eyes, nose length, etc.).
          Distinctive Features:
            Scars: [Insert Scars] Birthmarks: [Insert Birthmarks] Tattoos: [Insert Tattoos] Wrinkles & Age-related Changes: Specific details on any wrinkles or age-related features.
          Eye Features:
            Color: [Insert Eye Color]
            Shape: [Insert Eye Shape]
            Eyebrow Shape: Arch, thickness, and placement of eyebrows relative to the eyes.
            Space Between Eyes: Precise measurement of the distance between the two eyes.
          Nose, Lips, and Ears:
            Nose: Width, length, and curvature.
            Lips: Width, thickness, color, and texture.
            Ears: Shape, size, unique curves, edges, and the angle at which the ears sit relative to the face.
          Hair Characteristics:
            Hairline: Shape and position.
            Hair Texture: Texture, thickness, length and density.
            Facial Hair: Patterns, thickness, texture, density, length, and style of beard and mustache.
          Expressions:
            Common Expressions: Range of facial expressions and how they affect facial features and demeanor.
          3D Face Shape:
            Depth & Contour: Detailed description of the depth and contour of the face.
          3D Model: 
            Detailed model of the face’s structure, including the depth of the nose bridge and recession of the eyes.
          Dynamic Features:
            Emotion-based Changes: How the face changes with different emotions, including the speed and frequency of blinking.      

    ...and so on for the rest of the characters involved in the screenplay. Ensure that the appearance of the characters is as detailed as possible so that it can be used as a prompt to generate the video from SORA AI.
    Add the string "COMPLETE." in a new line at the end of character bible`
      }
    ];

    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-2024-08-06',
        messages,
        max_tokens: 10000,
        temperature: 0.2,
      },
      {
        headers: {
          Authorization: `Bearer ${API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log("Response:", response.data);

    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error generating response:', error.response ? error.response.data : error.message);
  }
};

export const generateCharacterBible2 = async (screenPlay) => {
  try {
    console.log("Screenplay:", screenPlay);

    const messages = [
      {
        role: "user",
        content: `Create a detailed character bible for the screenplay "${screenPlay}". The character bible should be structured as follows:

     Character Bible: List all the characters in the story. For each character, include:
          Appearance: Detailed physical description of the character. Ensure these details are consistent and emphasized for continuity.
          Attire: Clothing style, color, and any distinctive accessories.
          Height: [Insert Height] 
          Weight: [Insert Weight] 
          Build: [Slim, muscular, average, etc.]
          Age: [Insert Age] 
          Gender: [Insert Gender] 
          Ethnicity: [e.g., South Asian Indian, White Caucasian Scandinavian, etc.] 
          Race: [Insert Race] 
          Religion: [Insert Religion] 
          Nationality: [Insert Nationality]
          Personality Traits: Description of core personality traits, behaviors, and distinctive mannerisms. Recurring elements or attributes of the character that should be consistent across scenes.
          Jawline: Shape, width, and angle of the jawline. Cheekbones: Structure, height, and prominence.
          Chin: Contour and sharpness, including the jawline’s width and angle. Placement and contour of the chin.
          Skin : Skin color. Presence of freckles, moles, scars, birth marks, tattoos, Wrinkles & Age-related Changes or other skin markings.
          Face Width & Height: Specific measurements of the face. Forehead Shape and height.
          Facial Features Ratios: Detailed ratios between different parts of the face (e.g., distance between eyes, nose length, etc.).
          Eye Color: Color, Shape, Position and Precise distance and angles between the eyes 
          Eyebrow Shape: Arch, thickness, and placement of eyebrows relative to the eyes. Eyes Position: .
          Nose: Width, length, and curvature. Nose Position: Length, width, and placement of the nose relative to the eyes and mouth.
          Ears: Shape, size, unique curves, edges, and the angle at which the ears sit relative to the face.
          Mouth : Width of the mouth, thickness of lips, and the distance between the nose and mouth. 
          Lips: Width, thickness, color, and texture.
          Hairline: Shape and position. Hair Texture: Texture, thickness, length and density. Facial Hair: Patterns, thickness, texture, density, length, and style of beard and mustache.
          Common Expressions: Range of facial expressions and how they affect facial features and demeanor.
          Depth & Contour: Detailed description of the depth and contour of the face. Detailed model of the face’s structure, including the depth of the nose bridge and recession of the eyes.
          Emotion-based Changes: How the face changes with different emotions, including the speed and frequency of blinking.

    Here is an example format to follow:

    Character Bible:
     1. Character1:
          Appearance: Detailed physical description of the character. Ensure these details are consistent and emphasized for continuity.
          Attire: Clothing style, color, and any distinctive accessories.
          Height: [Insert Height] 
          Weight: [Insert Weight] 
          Build: [Slim, muscular, average, etc.]
          Age: [Insert Age] 
          Gender: [Insert Gender] 
          Ethnicity: [e.g., South Asian Indian, White Caucasian Scandinavian, etc.] 
          Race: [Insert Race] 
          Religion: [Insert Religion] 
          Nationality: [Insert Nationality]
          Personality Traits: Description of core personality traits, behaviors, and distinctive mannerisms. Recurring elements or attributes of the character that should be consistent across scenes.
          Jawline: Shape, width, and angle of the jawline. Cheekbones: Structure, height, and prominence.
          Chin: Contour and sharpness, including the jawline’s width and angle. Placement and contour of the chin.
          Skin : Skin color. Presence of freckles, moles, scars, birth marks, tattoos, Wrinkles & Age-related Changes or other skin markings.
          Face Width & Height: Specific measurements of the face. Forehead Shape and height.
          Facial Features Ratios: Detailed ratios between different parts of the face (e.g., distance between eyes, nose length, etc.).
          Eye Color: Color, Shape, Position and Precise distance and angles between the eyes 
          Eyebrow Shape: Arch, thickness, and placement of eyebrows relative to the eyes. Eyes Position: .
          Nose: Width, length, and curvature. Nose Position: Length, width, and placement of the nose relative to the eyes and mouth.
          Ears: Shape, size, unique curves, edges, and the angle at which the ears sit relative to the face.
          Mouth : Width of the mouth, thickness of lips, and the distance between the nose and mouth. 
          Lips: Width, thickness, color, and texture.
          Hairline: Shape and position. Hair Texture: Texture, thickness, length and density. Facial Hair: Patterns, thickness, texture, density, length, and style of beard and mustache.
          Common Expressions: Range of facial expressions and how they affect facial features and demeanor.
          Depth & Contour: Detailed description of the depth and contour of the face. Detailed model of the face’s structure, including the depth of the nose bridge and recession of the eyes.
          Emotion-based Changes: How the face changes with different emotions, including the speed and frequency of blinking.
     2. Character2:
          Appearance: Detailed physical description of the character. Ensure these details are consistent and emphasized for continuity.
          Attire: Clothing style, color, and any distinctive accessories.
          Height: [Insert Height] 
          Weight: [Insert Weight] 
          Build: [Slim, muscular, average, etc.]
          Age: [Insert Age] 
          Gender: [Insert Gender] 
          Ethnicity: [e.g., South Asian Indian, White Caucasian Scandinavian, etc.] 
          Race: [Insert Race] 
          Religion: [Insert Religion] 
          Nationality: [Insert Nationality]
          Personality Traits: Description of core personality traits, behaviors, and distinctive mannerisms. Recurring elements or attributes of the character that should be consistent across scenes.
          Jawline: Shape, width, and angle of the jawline. Cheekbones: Structure, height, and prominence.
          Chin: Contour and sharpness, including the jawline’s width and angle. Placement and contour of the chin.
          Skin : Skin color. Presence of freckles, moles, scars, birth marks, tattoos, Wrinkles & Age-related Changes or other skin markings.
          Face Width & Height: Specific measurements of the face. Forehead Shape and height.
          Facial Features Ratios: Detailed ratios between different parts of the face (e.g., distance between eyes, nose length, etc.).
          Eye Color: Color, Shape, Position and Precise distance and angles between the eyes 
          Eyebrow Shape: Arch, thickness, and placement of eyebrows relative to the eyes. Eyes Position: .
          Nose: Width, length, and curvature. Nose Position: Length, width, and placement of the nose relative to the eyes and mouth.
          Ears: Shape, size, unique curves, edges, and the angle at which the ears sit relative to the face.
          Mouth : Width of the mouth, thickness of lips, and the distance between the nose and mouth. 
          Lips: Width, thickness, color, and texture.
          Hairline: Shape and position. Hair Texture: Texture, thickness, length and density. Facial Hair: Patterns, thickness, texture, density, length, and style of beard and mustache.
          Common Expressions: Range of facial expressions and how they affect facial features and demeanor.
          Depth & Contour: Detailed description of the depth and contour of the face. Detailed model of the face’s structure, including the depth of the nose bridge and recession of the eyes.
          Emotion-based Changes: How the face changes with different emotions, including the speed and frequency of blinking.

    ...and so on for the rest of the characters involved in the screenplay. Ensure that the appearance of the characters is as detailed as possible so that it can be used as a prompt to generate the video from SORA AI.
    Add the string "COMPLETE." in a new line at the end of character bible`
      }
    ];

    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-2024-08-06',
        messages,
        max_tokens: 10000,
        temperature: 0.2,
      },
      {
        headers: {
          Authorization: `Bearer ${API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );

  //  console.log("Response:", response.data);

    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error generating response:', error.response ? error.response.data : error.message);
  }
};

export const generateConciseCharacterDescription = async (character) => {
  try {

    const messages = [
      {
        role: "user",
        content: `Generate a character description that should be easily understandable and interpreted by Dalle-3 so that it can generate an accurate image. 
        Do not lose any details. Make sure your response contains only the character description and nothing else so that it can be fed directly to dalle.
        Keep in mind that this prompt will be passed to dalle multiple times and I would like the results to be consistent. I would like these details to uniqiely identify a character.
        Limit your response to 800 characters. 

        Here is the character that I would like you to generate the description for "${character}"`
      }
    ];

    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-2024-08-06',
        messages,
        max_tokens: 10000,
        temperature: 0.2,
      },
      {
        headers: {
          Authorization: `Bearer ${API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );

  //  console.log("Response:", response.data);

    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error generating response:', error.response ? error.response.data : error.message);
  }
};