const getUserId = () => localStorage.getItem('userId') || 'guest';

const API_URL = 'https://alekhineai-app-267375680463.us-central1.run.app/';
//const API_URL = 'http://localhost:5000';

export const addProject = async (project) => {
  const userId = getUserId();

  try {
    // Step 1: Store project in MongoDB
    const response = await fetch(`${API_URL}/projects`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`  // Assuming JWT token for authorization
      },
      body: JSON.stringify({
        userId: userId,
        title: project.title,
        description: project.story,
      })
    });

    if (!response.ok) {
      throw new Error('Failed to store project in MongoDB');
    }

    const data = await response.json();
    const projectId = data.project_id;  // Project ID from MongoDB

    return projectId;

  } catch (error) {
    console.error('Error adding project:', error);
    throw new Error('Project creation failed');
  }
};

export const deleteProject = async (projectId) => {
  try {
    const response = await fetch(`${API_URL}/projects/${projectId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    if (!response.ok) {
      throw new Error('Failed to delete project');
    }
    console.log(`Project ${projectId} deleted successfully.`);
  } catch (error) {
    console.error('Error deleting project:', error);
    throw error;
  }
};

export const getProjects = async () => {
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('token');  // Retrieve the token

  try {
    const response = await fetch(`${API_URL}/projects/${userId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (response.status === 404) {
      console.warn("No projects found for this user.");
      return [];  // Return an empty array if no projects are found
    }

    if (!response.ok) {
      throw new Error('Failed to fetch projects from MongoDB');
    }

    const projects = await response.json();
    return projects;

  } catch (error) {
    console.error('Error fetching projects:', error);
    throw error;  // Re-throw other errors
  }
};


export const saveScreenplay = async (projectId, screenplay) => {

  try {
    // Step 1: Store screenplay in MongoDB
    const response = await fetch(`${API_URL}/screenplays`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //  'Authorization': `Bearer ${localStorage.getItem('token')}`  // Assuming JWT token for authorization
      },
      body: JSON.stringify({
        projectId: projectId,
        screenplay: screenplay
      })
    });

    if (!response.ok) {
      throw new Error('Failed to store screenplay in MongoDB');
    }

    const data = await response.json();
    const screenplayId = data.screenplay_id;  // Screenplay ID from MongoDB

    return screenplayId;

  } catch (error) {
    console.error('Error saving screenplay:', error);
    throw new Error('Screenplay save failed');
  }
};

export const getScreenplay = async (projectId) => {
  const userId = localStorage.getItem('userId') || 'guest';

  try {
    // Step 1: Fetch screenplay from MongoDB
    const response = await fetch(`${API_URL}/screenplays/${projectId}`, {
      method: 'GET',
      headers: {
        //  'Authorization': `Bearer ${localStorage.getItem('token')}`  // Assuming JWT token for authorization
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch screenplay from MongoDB');
    }

    const data = await response.json();
    const screenplay = data.screenplay;

    return screenplay;

  } catch (error) {
    console.error('Error fetching screenplay:', error);
    throw new Error('Failed to retrieve screenplay');
  }
};

export const saveScenes = async (projectId, scenes) => {
  try {
    const response = await fetch(`${API_URL}/projects/${projectId}/scenes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ scenes: scenes })
    });

    if (!response.ok) { throw new Error('Failed to store scenes in MongoDB'); }

  } catch (error) {
    console.error('Error saving scenes:', error);
    throw new Error('Scene save failed');
  }
};

export const getScenes = async (projectId) => {
  const userId = getUserId();

  try {
    const response = await fetch(`${API_URL}/projects/${projectId}/scenes`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (!response.ok) throw new Error('Failed to fetch scenes from MongoDB');

    const data = await response.json();
    const scenes = data.scenes;

    return scenes;

  } catch (error) {
    console.error('Error fetching scenes from MongoDB, falling back to IndexedDB:', error);
  }
};

// Save Character Bible in MongoDB and IndexedDB
export const saveCharacterBible = async (projectId, characterBible) => {

  try {

    const characterBibleObject = mapToObject(characterBible);

    // Step 1: Store character bible in MongoDB
    const response = await fetch(`${API_URL}/characterbibles`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //  'Authorization': `Bearer ${localStorage.getItem('token')}`  // Assuming JWT token for authorization
      },
      body: JSON.stringify({
        projectId: projectId,
        characterBible: characterBibleObject  // Array of character descriptions and images
      })
    });

    if (!response.ok) {
      throw new Error('Failed to store character bible in MongoDB');
    }

    const data = await response.json();
    const characterBibleId = data.character_bible_id;

    return characterBibleId;

  } catch (error) {
    console.error('Error saving character bible:', error);
    throw new Error('Character bible save failed');
  }
};


export const getCharacterBible = async (projectId) => {

  try {
    const response = await fetch(`${API_URL}/characterbibles/${projectId}`, {
      method: 'GET',
      headers: {
        //  'Authorization': `Bearer ${localStorage.getItem('token')}`  // Assuming JWT token for authorization
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch character bible from MongoDB');
    }

    const data = await response.json();
    const characterBible = data.characterBible;

    return objectToMap(characterBible);

  } catch (error) {
    console.error('Error fetching character bible:', error);
    throw new Error('Failed to retrieve character bible');
  }
};

export const saveImageOnCloud = async (image_url, image_id) => {
  try {
    const response = await fetch(`${API_URL}/upload-image`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //  'Authorization': `Bearer ${localStorage.getItem('token')}`  // Assuming JWT token for authorization
      },
      body: JSON.stringify({
        image_url: image_url,
        image_name: image_id
      })
    });

    if (!response.ok) {
      throw new Error('Failed to store character bible in MongoDB');
    }

    const data = await response.json();
    return data.url;

  } catch (error) {
    console.error('Error saving image to the cloud:', error);
    throw new Error('Failed to save image to the cloud');
  }
}

export const saveVideoOnCloud = async (video_url, video_id) => {
  try {
    const response = await fetch(`${API_URL}/upload-video`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //  'Authorization': `Bearer ${localStorage.getItem('token')}`  // Assuming JWT token for authorization
      },
      body: JSON.stringify({
        video_url: video_url,
        video_name: video_id
      })
    });

    if (!response.ok) {
      throw new Error('Failed to store character bible in MongoDB');
    }

    const data = await response.json();
    return data.url;

  } catch (error) {
    console.error('Error saving image to the cloud:', error);
    throw new Error('Failed to save image to the cloud');
  }
}


export const saveAudioOnCloud = async (audioFile, audioName) => {
  if (!(audioFile instanceof File)) {
    throw new Error("Invalid file input. Please provide a File object.");
  }

  // Prepare form data to send in the request
  const formData = new FormData();
  formData.append("file", audioFile);
  formData.append("audio_name", audioName);

  try {
    // Make the POST request to upload the audio file
    const response = await fetch(`${API_URL}/upload-audio`, {
      method: 'POST',
      body: formData,
    });

    // Check if the response is successful
    if (!response.ok) {
      throw new Error(`Error uploading audio: ${response.statusText}`);
    }

    // Parse the JSON response
    const result = await response.json();

    return result.url;  // Return the URL of the uploaded audio

  } catch (error) {
    console.error("Failed to upload audio:", error);
    throw error;  // Rethrow error for further handling if needed
  }
}


const mapToObject = (map) => {
  const obj = {};
  for (const [key, value] of map.entries()) {
    obj[key] = value;
  }
  return obj;
};

const objectToMap = (obj) => {
  const map = new Map();
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      map.set(key, obj[key]);
    }
  }
  return map;
};
