import axios from 'axios';

const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

export const generateImageFromPromptAndContext = async (sceneDescription, context) => {
  try {
    const prompt = `${context} Scene description: ${sceneDescription}. Realistic, shot on a 35mm lens, high contrast, high quality, ultra high definition, cinematic, widescreen. No text of any kind on the images`;
    console.log("Dalle prompt:", prompt);
    const response = await axios.post(
      'https://api.openai.com/v1/images/generations',
      {
        prompt: prompt.substring(0, 4000),
        //      prompt: `Generate image for a scene which is part of the screenplay for an expensive movie. The Scene description is: ${sceneDescription}. The Screenplay is: ${context}. Images should be realistic, high contrast, high quality, ultra high definition, cinematic, widescreen. No text of any kind should be present on the images`,
        model: "dall-e-3",
        style: "vivid",
        quality: 'hd',
        n: 1,
        size: '1792x1024',
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${API_KEY}`,
        },
      }
    );
    return response.data.data[0].url;
  } catch (error) {
    console.error('Error generating image:', error.response ? error.response.data : error.message);
    return 'https://via.placeholder.com/1024'; // Placeholder image in case of error
  }
};

export const generateImageWithDetails = async (sceneDescription, context, characterBible, environment, characterExpressions) => {
  try {
    // Ensure characterBible and characterExpressions are strings
    const formattedCharacterBible = typeof characterBible === 'string'
      ? characterBible
      : Array.isArray(characterBible)
        ? characterBible.map(character => `- ${character}`).join('\n')
        : '';

    const formattedCharacterExpressions = typeof characterExpressions === 'string'
      ? characterExpressions
      : Array.isArray(characterExpressions)
        ? characterExpressions.map(expression => `- ${expression}`).join('\n')
        : '';

    const prompt = `
      Context:
       ${context}. 
      
      Style:
      - Realistic style with high contrast, dramatic lighting, cinematic look.
      - Deep shadows, high quality, and ultra-high-definition 35mm film style
      - Wide aspect ratio, reminiscent of scenes from a Christopher Nolan movie
      
      Scene Description:
      ${sceneDescription}
      
      Environment:
      ${environment}
      
      Character Bible:
      ${formattedCharacterBible}
      
      Character Expressions:
      ${formattedCharacterExpressions}
    `;
    //console.log("Dalle prompt with details:", prompt.substring(0,4000));
    const response = await axios.post(
      'https://api.openai.com/v1/images/generations',
      {
        prompt: prompt.substring(0,4000),
        model: "dall-e-3",
        style: "vivid",
        quality: 'hd',
        n: 1,
        size: '1792x1024',
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${API_KEY}`,
        },
      }
    );
    return response.data.data[0].url;
  } catch (error) {
    console.error('Error generating image:', error.response ? error.response.data : error.message);
    return 'https://via.placeholder.com/1024'; // Placeholder image in case of error
  }
};

export const generateCharacterImage = async (character) => {
  try {
    const prompt = `      
      Style:
      - Realistic style with high contrast, dramatic lighting, cinematic look.
      - Deep shadows, high quality, and ultra-high-definition 35mm film style
      
      Generate the character below:

      Character :
      ${character}
    `;
    console.log("Dalle prompt with details:", prompt.substring(0,4000));
    const response = await axios.post(
      'https://api.openai.com/v1/images/generations',
      {
        prompt: prompt.substring(0,4000),
        model: "dall-e-3",
        style: "vivid",
        quality: 'hd',
        n: 1,
        size: '1024x1024',
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${API_KEY}`,
        },
      }
    );
    return response.data.data[0].url;
  } catch (error) {
    console.error('Error generating image:', error.response ? error.response.data : error.message);
    return 'https://via.placeholder.com/1024'; // Placeholder image in case of error
  }
};


export const generateImageFromPrompt = async (prompt) => {
  const context = 'Context specific to your application or scene'; // Replace with actual context if needed
  return await generateImageFromPromptAndContext(prompt, context);
};
