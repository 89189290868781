import React, { useState, useEffect, useRef } from 'react';
import './ProjectSidebar.css';
import { getScreenplay, getScenes, getCharacterBible, deleteProject } from '../services/db'; // Import necessary services

const ProjectSidebar = ({ projects, onSelectProject, onCreateProject, selectedProject, logout, onRemoveProject }) => {
  const [expandedNodes, setExpandedNodes] = useState({});
  const [loadingNodes, setLoadingNodes] = useState({});
  const [selectedProjectId, setSelectedProjectId] = useState(selectedProject ? selectedProject._id : null);
  const [contextMenu, setContextMenu] = useState(null);
  const contextMenuRef = useRef(null);

  const toggleNodeExpansion = async (projectId) => {
    if (expandedNodes[projectId]) {
      setExpandedNodes(prevExpandedNodes => ({
        ...prevExpandedNodes,
        [projectId]: false
      }));
    } else {
      // Set the loading state for this project
      setLoadingNodes(prevLoadingNodes => ({
        ...prevLoadingNodes,
        [projectId]: true
      }));

      // Fetch the project children
      const screenplay = await getScreenplay(projectId);
      const scenes = await getScenes(projectId);
      const characterBible = await getCharacterBible(projectId);

      // Update the project with its children
      const updatedProjects = projects.map(project => {
        if (project._id === projectId) {
          return {
            ...project,
            screenplay,
            scenes,
            characterBible
          };
        }
        return project;
      });

      // Clear the loading state and expand the node
      setLoadingNodes(prevLoadingNodes => ({
        ...prevLoadingNodes,
        [projectId]: false
      }));
      setExpandedNodes(prevExpandedNodes => ({
        ...prevExpandedNodes,
        [projectId]: true
      }));
    }
  };

  useEffect(() => {
    if (selectedProject) {
      setSelectedProjectId(selectedProject._id);
    }
  }, [selectedProject]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
        handleCloseContextMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSelectProject = (project) => {
    if (project) {
      setSelectedProjectId(project._id);  // Only set if project exists
      onSelectProject(project);
    } else {
      setSelectedProjectId(null);
      onSelectProject(null);
    }
  };

  const handleCreateProject = () => {
    setSelectedProjectId(null); // Deselect any selected project
    onCreateProject(); // Show the create project dialog
  };

  const handleRightClick = (e, projectId) => {
    e.preventDefault();
    setContextMenu({ mouseX: e.clientX - 2, mouseY: e.clientY - 4, projectId });
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handleDeleteProject = async () => {
    if (contextMenu && contextMenu.projectId) {
      await deleteProject(contextMenu.projectId);
      handleCloseContextMenu();
      setSelectedProjectId(null);  // Clear selection after deletion
      onRemoveProject(contextMenu.projectId);
      onSelectProject(null);       // Deselect project in the parent component
    }
  };

  const renderProjectChildren = (project) => {
    if (loadingNodes[project._id]) {
      return <li>Loading...</li>;
    }

    if (!expandedNodes[project._id]) {
      return null;
    }

    return (
      <ul className="project-children">
        {project.screenplay && (
          <li className="project-child">
            <span>Screenplay</span>
          </li>
        )}
        {project.characterBible && (
          <li className="project-child">
            <span>Character Bible</span>
          </li>
        )}
        {project.scenes && project.scenes.length > 0 && (
          <li className="project-child">
            <span className="arrow" onClick={(e) => { e.stopPropagation(); toggleNodeExpansion(`${project._id}-scenes`); }}>
              {expandedNodes[`${project._id}-scenes`] ? '▼' : '▶'}
            </span>
            <span>Scenes</span>
            {expandedNodes[`${project._id}-scenes`] && (
              <ul className="scene-list">
                {project.scenes.map((scene, index) => (
                  <li key={index} className="scene-item">
                    <span className="arrow" onClick={(e) => { e.stopPropagation(); toggleNodeExpansion(`${project._id}-scene-${index}`); }}>
                      {expandedNodes[`${project._id}-scene-${index}`] ? '▼' : '▶'}
                    </span>
                    <span>Scene {index + 1}</span>
                    {expandedNodes[`${project._id}-scene-${index}`] && (
                      <ul className="media-list">
                        {scene.image && (
                          <li className="media-item">
                            <span>Image</span>
                          </li>
                        )}
                        {scene.audio && scene.audio.length > 0 && (
                          <li className="media-item">
                            <span className="arrow" onClick={(e) => { e.stopPropagation(); toggleNodeExpansion(`${project._id}-scene-${index}-audio`); }}>
                              {expandedNodes[`${project._id}-scene-${index}-audio`] ? '▼' : '▶'}
                            </span>
                            <span>Audio</span>
                            {expandedNodes[`${project._id}-scene-${index}-audio`] && (
                              <ul className="audio-list">
                                {scene.audio.map((audioFile, audioIndex) => (
                                  <li key={audioIndex} className="audio-item">
                                    <span>Dialogue {audioIndex + 1}</span>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        )}
                        {scene.video && (
                          <li className="media-item">
                            <span>Video</span>
                          </li>
                        )}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </li>
        )}
      </ul>
    );
  };

  return (
    <div className="project-sidebar">
      <div className="header-container">
        <h2>Workspace</h2>
        <button className="create-project-button" onClick={handleCreateProject}>+</button> {/* Updated button click handler */}
      </div>
      <ul className="project-list">
        {projects.map((project) => (
          <li 
          key={project._id} 
          className={`project-item ${project._id === selectedProjectId ? 'selected' : ''}`}
          onContextMenu={(e) => handleRightClick(e, project._id)} >
            <div className="project-header">
              <span
                className="arrow"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleNodeExpansion(project._id);
                }}
              >
                {expandedNodes[project._id] ? '▼' : '▶'}
              </span>
              <span
                className="project-title"
                onClick={() => handleSelectProject(project)}
              >
                {project.title}
              </span>
            </div>
            {renderProjectChildren(project)}
          </li>
        ))}
      </ul>

      {contextMenu && (
        <div
          className="context-menu"
          style={{ top: contextMenu.mouseY, left: contextMenu.mouseX }}
          onClick={handleCloseContextMenu}
          ref={contextMenuRef}
        >
          <button onClick={handleDeleteProject}>Delete</button>
        </div>
      )}

      <button className="logout-button" onClick={logout}>
      ⏻
      </button>
    </div>
  );
};

export default ProjectSidebar;
