import React, { useState } from 'react';
import './LoginPage.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleAuth = async (action) => {
    try {

      const API_URL = 'https://alekhineai-app-267375680463.us-central1.run.app/';
      //const API_URL = 'http://localhost:5000';

      const endpoint = action === 'login' ? '/login' : '/register';
      const response = await axios.post(`${API_URL}${endpoint}`, { email: username, password });

      if (response.status === 200 && response.data.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userId', response.data.user_id);
        navigateToHome();
      } else {
        setError(`Failed: ${response.data.error || 'Invalid credentials'}`);
      }
    } catch (error) {
      setError(`Error: ${error.response?.data?.message || error.message}`);
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
    }
  };

  const navigateToHome = () => {
      window.location.href = '/home';
      //navigate('/home');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="button-group">
          <button className="login-button" type="button" onClick={() => handleAuth('login')}>Login</button>
          <button className="register-button" type="button" onClick={() => handleAuth('register')}>Register</button>
        </div>
        {error && <p className="error-message">{error}</p>}
      </form>
    </div>
  );
};

export default LoginPage;
