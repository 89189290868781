import React, { useState } from 'react';
import './ScreenplayTile.css';
import loadingGif from './assets/image/loading1.gif'; // Make sure the path is correct

const ScreenplayTile = ({ detailedScreenplay, isLoading, onGenerateScenes, isGeneratingScenes, isEditing, onSaveEditedScreenplay, maximizeScreenPlay, toggleMaximizeScreenPlay, characterBible }) => {
  const [showFullScreenplay, setShowFullScreenplay] = useState(false);
  const [editedScreenplay, setEditedScreenplay] = useState(detailedScreenplay);

  const handleShowHideScreenplay = () => {
    toggleMaximizeScreenPlay();
  };


  const renderDetailedScreenplay = () => {
    if (isLoading) {
      return <img src={loadingGif} alt="Loading..." className="loading-animation" />;
    }

    if (!detailedScreenplay) return null;

    const lines = detailedScreenplay.split('\n');
    const previewLines = lines.slice(0, 10).join('\n');
    const fullScreenplay = lines.join('\n');

    if (isEditing) {
      return (
        <div>
          <textarea
            className="screenplay-edit-area"
            value={editedScreenplay}
            onChange={(e) => setEditedScreenplay(e.target.value)}
            rows={10}
          />
          <button
            className="save-edit-button"
            onClick={() => onSaveEditedScreenplay(editedScreenplay)}
          >
            Save
          </button>
        </div>
      );
    }

    return (
      <div>
        <pre>{showFullScreenplay ? fullScreenplay : previewLines}</pre>
        <div className="buttons-container">
          <button
            className="generate-scenes-button"
            onClick={onGenerateScenes}
            disabled={isGeneratingScenes || !characterBible.size>0}
          >
            {isGeneratingScenes ? 'Generating Scenes...' : 'Generate Scenes 🎥'}
          </button>
          {lines.length > 10 && (
            <button className="show-more-button" onClick={() => setShowFullScreenplay(!showFullScreenplay)}>
              {showFullScreenplay ? 'Show Less' : 'Show More'}
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="screenplay-tile">
      <div className="header-container">
        <h2>Detailed Screenplay</h2>
        <button className="min-max-button" onClick={handleShowHideScreenplay}> {maximizeScreenPlay ? '📰' : '🗞'}</button>
      </div>
      {maximizeScreenPlay ? renderDetailedScreenplay() : null}
    </div>
  );
};

export default ScreenplayTile;
