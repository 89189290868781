import React, { useState, useEffect } from 'react';
import './SceneTile.css';

const SceneTile = ({ image, title, duration, summary, additionalInfo, onRegenerateImage, onRegenerateImagePostEdit, onGenerateVideo }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [visualPrompt, setVisualPrompt] = useState(additionalInfo.visual);
  const [sceneImage, setSceneImage] = useState(image);
  const [audioUrls, setAudioUrls] = useState([]);

  useEffect(() => {
    setSceneImage(image);
  }, [image]);

  useEffect(() => {
    const urls = additionalInfo.dialogues.map(dialogue => dialogue.audio);
    setAudioUrls(urls);
  }, [additionalInfo.dialogues]);

  const handleToggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const handleRegenerateImage = async () => {
    onRegenerateImage(visualPrompt);
  };

  const handleVisualPromptClick = () => {
    setIsEditing(true);
  };

  const handleVisualPromptChange = (e) => {
    setVisualPrompt(e.target.value);
  };

  const handleVisualPromptKeyDown = async (e) => {
    if (e.key === 'Enter') {
      setIsEditing(false);
      onRegenerateImagePostEdit(visualPrompt);
    }
  };

  const handleGenerateVideo = async () => {
    await onGenerateVideo();
  };

  return (
    <div className="scene-tile">
      <div className="title-bar">
        <h3>{title}</h3>
        <button className="regenerate-button" onClick={handleRegenerateImage}>⟳</button>
      </div>
      <img src={sceneImage} alt={title} className="scene-image" onClick={handleToggleDetails} />
      <div className="scene-overview">
        <p><strong>Duration:</strong> {duration}</p>
        {showDetails && (
        <p>{summary}</p>
        )}
      </div>
      {!showDetails && (
        <div className="additional-info">
          <p><strong>Visual Prompt:</strong> <br />
            {isEditing ? (
              <input
                type="text"
                value={visualPrompt}
                onChange={handleVisualPromptChange}
                onKeyDown={handleVisualPromptKeyDown}
                onBlur={() => setIsEditing(false)}
                autoFocus
              />
            ) : (
              <span onClick={handleVisualPromptClick}>{visualPrompt}</span>
            )}
          </p>
          <br />
          <p><strong>Environment:</strong> <br />{additionalInfo.environment}</p>
          <br />
          <p><strong>Character Emotions:</strong></p>
          <ul>
            {additionalInfo.characterExperessions.map((characterExpression, index) => (
              <li key={index}>{characterExpression}</li>
            ))}
          </ul>
          <p><strong>Dialogues:</strong></p>
          <ul className="scenes-audio-list">
            {additionalInfo.dialogues.map((dialogue, index) => (
              <li key={index} className="dialogue-container">
                {audioUrls[index] && (
                  <div className="audio-container">
                    <audio controls>
                      <source src={audioUrls[index]} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                    {dialogue.text}
                  </div>
                )}
              </li>
            ))}
          </ul>
          <p><strong>Background Music:</strong></p>
          <div className="audio-container">
            <audio controls>
              <source src={additionalInfo.music.audio} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
            {additionalInfo.music.text}
          </div>
          <div className="video-button-container">
            <button type="button" className="video-generate-button" onClick={handleGenerateVideo}>
              Generate Video ▶
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SceneTile;
