import axios from 'axios';

const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

export const getSpeechFromText = async (text) => {
    try {
        const response = await axios.post('https://api.openai.com/v1/audio/speech', {
            model: 'tts-1',
            input: text,
            voice: 'alloy'
        }, {
            headers: {
                'Authorization': `Bearer ${API_KEY}`,
                'Content-Type': 'application/json'
            },
            responseType: 'blob' // To handle the audio file as a binary blob
        });

        // Create a URL for the audio blob
        const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
        const audioUrl = URL.createObjectURL(audioBlob);

        return audioUrl;
    } catch (error) {
        console.error('Error generating audio:', error);
        throw error;
    }
};