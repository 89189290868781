import React, { useState } from 'react';
import './NewProjectDialog.css';

const NewProjectDialog = ({ onClose, onCreate }) => {
  const [title, setTitle] = useState('');
  const [story, setStory] = useState('');

  const handleCreate = () => {
    onCreate({ title, story });
    onClose();
  };

  return (
    <div className="new-project-dialog">
      <div className="title-bar">
        <h3>Create New Project</h3>
      </div>
      <br/>
      <label>Title:</label>
      <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
      <br/>
      <label>Concept:</label>
      <textarea value={story} onChange={(e) => setStory(e.target.value)}></textarea>
      <br/><br/>
      <button onClick={handleCreate}>Create Project</button>
      <button onClick={onClose}>Cancel</button>
    </div>
  );
};

export default NewProjectDialog;
