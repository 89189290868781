import React, { useState } from 'react';
import './CharacterSlideshow.css';
import loadingGif from './assets/image/loading1.gif'; // Make sure the path is correct
import CharacterTile from './CharacterTile';

const CharacterSlideshow = ({ characters, setIsOverlayVisible, onSaveCharacter }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const goToNext = () => {
    if (currentIndex < characters.length - 4) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const visibleCharacters = characters.slice(currentIndex, currentIndex + 4);

  return (
    <div className="character-slideshow-container">
      <button className="prev" onClick={goToPrevious} disabled={currentIndex === 0}>❮</button>
      <div className="character-slideshow">
        {visibleCharacters.map((character, index) => (
          <CharacterTile 
          key={character.name}
          character={character} 
          setIsOverlayVisible={setIsOverlayVisible}
          onSaveCharacter={onSaveCharacter}
          />
        ))}
      </div>
      <button className="next" onClick={goToNext} disabled={currentIndex >= characters.length - 4}>❯</button>
    </div>
  );
};

export default CharacterSlideshow;