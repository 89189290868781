import React, { useState, useEffect, useRef } from 'react';
import './CharacterTile.css';

const processDescription = (description) => {
  const lines = description.split('\n');

  return lines.map((line, index) => {
    const [heading, content] = line.split(/:(.+)/);
    return (
      <div key={index}>
        {content ? (
          <>
            <h4 className="character-heading">{heading.trim()}:</h4>
            <p className="character-content">{content.trim()}</p>
          </>
        ) : (
          <p className="character-content">{heading.trim()}</p>
        )}
      </div>
    );
  });
};


const CharacterTile = ({ character, setIsOverlayVisible, onSaveCharacter }) => {
  const [isEnlarged, setIsEnlarged] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState(character.details.description);
  const tileRef = useRef(null); // Reference to the character tile

  useEffect(() => {
    setDescription(character.details.description);
  }, [character]);

  const toggleEnlarge = (e) => {
    if (e.target.tagName === 'TEXTAREA' || e.target.tagName === 'BUTTON') {
      return;
    }
    setIsEnlarged(true);
    setIsOverlayVisible(true);
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleSaveClick = (e) => {
    e.stopPropagation();
    character.details.description = description;
    onSaveCharacter(character);  // Save changes to the parent component
    setIsEditing(false);
  };

  const handleCharacterDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleClickOutside = (e) => {
    if (tileRef.current && !tileRef.current.contains(e.target)) {
      setIsEnlarged(false);
      setIsEditing(false);
      setIsOverlayVisible(false);
    }
  };

  useEffect(() => {
    if (isEnlarged) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEnlarged]);

  return (
    <div
      className={`character-tile ${isEnlarged ? 'enlarged' : ''}`}
      onClick={toggleEnlarge}
      ref={tileRef} // Attach the reference to the tile
    >
      <div className="character-header">
        <div className="character-info">
          <h1>{character.name}</h1>
          <div className="character-details scrollable">
            {isEditing ? (
              <textarea
                value={description}
                onChange={handleCharacterDescriptionChange}
              />
            ) : (
              processDescription(character.details.description)
            )}
          </div>
        </div>
        <img src={character.details.image} alt={character.name} className="character-image" />
      </div>
      {isEnlarged && (
        <div className="vertical-buttons-screenplay-container">
          <button className="toolbar-button" onClick={handleEditClick}>✎</button>
          {isEditing && (
            <button className="toolbar-button" onClick={handleSaveClick}>💾</button>
          )}
        </div>
      )}
    </div>
  );
};

export default CharacterTile;
