import React, { useState, useCallback, useEffect } from 'react';
import {
  addProject, getProjects, saveScreenplay, getScreenplay, saveScenes, getScenes, saveVideoOnCloud,
  saveAudioOnCloud, saveCharacterBible, getCharacterBible, saveImageOnCloud
} from '../services/db';
import {
  formatCharacterBible, parseCharacterDescriptions, parseScenesFromDetailedScreenplay,
  downloadToBlob, blobToBase64, base64ToBlob, generateCharacterDescriptions, generateCharacterDescriptionsConcise
} from '../utilities/utility';
import { generateScreenplay, generateCharacterBible, generateCharacterBible2, generateConciseCharacterDescription } from '../services/chatGPTService';
import { generateImageWithDetails, generateCharacterImage } from '../services/dalleService';
import { getSpeechFromText } from '../services/ttsService';
import { generateVideoFromPrompt } from '../services/soraService';
import { generateMusicFromPrompt } from '../services/musicService';
import SceneTile from './SceneTile';
import ScreenplayTile from './ScreenplayTile';
import VideoTile from './VideoTile';
import ProjectSidebar from './ProjectSidebar';
import NewProjectDialog from './NewProjectDialog';
import CharacterSlideshow from './CharacterSlideshow';
import loadingGif from './assets/image/loading1.gif';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './MainPage.css';

const MainPage = () => {
  const [projects, setProjects] = useState([]);
  const [currentProject, setCurrentProject] = useState(null);
  const [showNewProjectDialog, setShowNewProjectDialog] = useState(false);
  const [scenes, setScenes] = useState([]);
  const [detailedScreenplay, setDetailedScreenplay] = useState('');
  const [loading, setLoading] = useState(false);
  const [characterBible, setCharacterBible] = useState({});
  const [isScreenplayRendered, setIsScreenplayRendered] = useState(false);
  const [isScenesGenerating, setIsScenesGenerating] = useState(false);
  const [isScreenplayLoading, setIsScreenplayLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isCharacterSlideshow, setIsCharacterSlideshow] = useState(false);
  const [isCharacterBibleLoading, setIsCharacterBibleLoading] = useState(false);
  const [maximizeScreenPlay, setMaximizeScreenPlay] = useState(true);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [backendAPITestString, setBackendAPITestString] = useState('');
  const navigate = useNavigate();

  useEffect(() => {

    axios.get('https://alekhineai-app-267375680463.us-central1.run.app/connectivity-test')
      .then(response => {
        console.log('cloud', response.data);
      })
      .catch(error => {
        console.error('cloud', error);
      });

    axios.get('http://localhost:5000/connectivity-test')
      .then(response => {
        console.log('local', response.data);
      })
      .catch(error => {
        console.error('local', error);
      });

      loadProjects();  
  }, []);


  const loadProjects = async () => {
    try {
      const projects = await getProjects();
      setProjects(projects); 
    } catch (error) {
      console.error("Error loading projects:", error);
    }
  };

  const deselectProject = () => {
    setCurrentProject(null);
    setDetailedScreenplay('');
    setScenes([]);
    setCharacterBible({});
  };

  const handleCreateProject = async (project) => {
    const _id = await addProject(project);
    const newProject = {
      ...project,
      _id: _id,
      scenes: [],
      screenplay: '',
      characterBible: ''
    };

    const newProjects = [...projects, newProject];
    setProjects(newProjects);
    setCurrentProject(newProject);
    setIsScreenplayLoading(true);
    handleGenerate(newProject);
    setMaximizeScreenPlay(true);
  };

  const handleSelectProject = async (project) => {
    if (!project) {
      // Clear state if no project is selected
      setCurrentProject(null);
      setDetailedScreenplay('');
      setCharacterBible(new Map());
      setScenes([]);
      setMaximizeScreenPlay(false);
      setIsScreenplayRendered(false);
      setIsCharacterSlideshow(false);
      return;
    }
  
    // Existing logic for setting up a selected project
    setMaximizeScreenPlay(false);
    setCurrentProject(project);
  
    const screenplay = await getScreenplay(project._id);
    setDetailedScreenplay(screenplay);
  
    const storedCharacterBible = await getCharacterBible(project._id);
    const characterBibleMap = storedCharacterBible instanceof Map && storedCharacterBible.size > 0
      ? storedCharacterBible
      : new Map();
    setCharacterBible(characterBibleMap);
  
    const storedScenes = await getScenes(project._id);
    if (storedScenes && storedScenes.length > 0) {
      setScenes(storedScenes);
      setMaximizeScreenPlay(false);
      setIsScreenplayRendered(true);
    } else {
      setScenes([]);
      setMaximizeScreenPlay(true);
      setIsScreenplayRendered(true);
      setIsCharacterSlideshow(true);
    }
  };

  const handleRemoveProject = (deletedProjectId) => {
    setProjects((prevProjects) => prevProjects.filter(project => project._id !== deletedProjectId));
  };

  const handleGenerate = async (project) => {
    setLoading(true);
    setIsScreenplayRendered(false);
    try {
      const story = { title: project.title, description: project.story };
      const detailedScreenplayText = await generateScreenplay(story);
      const detailedScreenplayResponse = detailedScreenplayText.replace(/[*-]/g, '');
      setDetailedScreenplay(detailedScreenplayResponse);

      setIsScreenplayRendered(true);
      setIsScreenplayLoading(false);
      setIsCharacterBibleLoading(true);

      await saveScreenplay(project._id, detailedScreenplayResponse);

      const characterBibleGenerated = await generateCharacterBible2(detailedScreenplayResponse);
      const characterBibleResponse = characterBibleGenerated.replace(/[-*#]/g, '');
      const characterBibleMatch = characterBibleResponse.match(/Character Bible:\n([\s\S]+?)\nCOMPLETE./);
      let characterBibleContent = characterBibleMatch ? characterBibleMatch[1] : '';
      let characterMap = parseCharacterDescriptions(characterBibleContent);

      await Promise.all(Array.from(characterMap.entries()).map(async ([name, details]) => {
        const consiseCharacterString = await generateConciseCharacterDescription(details.description);
        details.conciseDescription = consiseCharacterString;
        const imageUrl = await generateCharacterImage(details.description);
        details.image = await saveImageOnCloud(imageUrl, `${project._id}_character_${name}`);
      }));

      setCharacterBible(characterMap);
      await saveCharacterBible(project._id, characterMap);
      setIsCharacterSlideshow(true);
      setIsCharacterBibleLoading(false);

    } catch (error) {
      console.error('Error generating screenplay:', error);
      setIsScreenplayLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateScenes = async () => {
    setIsScenesGenerating(true);
    setLoading(true);
    try {
        const context = `This is a continuous narrative for the story titled "${currentProject.title}". 
        All scenes should have a consistent style and theme, and should be shot from the same perspective, maintaining the same color palette and artistic style. 
        Generate an image accurately depicting the scene based on the information below.`;

        const parsedScenes = parseScenesFromDetailedScreenplay(detailedScreenplay);
        const sceneData = await Promise.all(parsedScenes.map(async (scene) => {
            const characterBibleString = generateCharacterDescriptionsConcise(scene.characters, characterBible);

            // Generate media for the scene
            const [imageURL, dialoguesWithAudio, musicURL] = await Promise.all([
                generateImageWithDetails(scene.visualPrompt, context, characterBibleString, scene.environment, scene.characterExperessions),
                Promise.all(scene.dialogues.map(async (dialogue, index) => {
                    const formattedDialog = dialogue.split(':').pop();
                    const audioURL = await getSpeechFromText(formattedDialog);
                    
                    // Convert audio URL (blob) to base64 to prepare for MongoDB storage
                    const audioBlob = await downloadToBlob(audioURL);
                    const base64Audio = await blobToBase64(audioBlob);

                    const audioFile = new File([audioBlob], `${currentProject._id}_audio_${scene.number}_${index}.mp3`, { type: "audio/mpeg" });
                    const audioCloudURL = await saveAudioOnCloud(audioFile, `${currentProject._id}_audio_${scene.number}_${index}`);

                    return { text: dialogue, audio: audioCloudURL };
                })),
                generateMusicFromPrompt(scene.backgroundMusic)
            ]);

            // Handle music to cloud
            const musicBlob = await downloadToBlob(musicURL);

            const coverImageURL = await saveImageOnCloud(imageURL, `${currentProject._id}_scene_${scene.number}`);

            return {
                number: scene.number,
                image: coverImageURL,
                title: `Scene ${scene.number}: ${scene.title}`,
                duration: scene.duration,
                summary: scene.visualPrompt.substring(0, 200) + '...',
                additionalInfo: {
                    visual: scene.visualPrompt,
                    setting: scene.settingDetails,
                    environment: scene.environment,
                    characterExperessions: scene.characterExperessions,
                    dialogues: dialoguesWithAudio, // Audio stored as base64
                    music: { text: scene.backgroundMusic, audio: musicURL },  // Music in base64
                    context: context,
                    characters: scene.characters
                },
                video: null
            };
        }));


        setScenes(sceneData);
        await saveScenes(currentProject._id, sceneData);
        setMaximizeScreenPlay(false);
    } catch (error) {
        console.error('Error generating scenes:', error);
    } finally {
        setIsScenesGenerating(false);
        setLoading(false);
    }
};

  const handleLogout = () => {
    localStorage.removeItem('auth');  // Clear authentication flag
    localStorage.removeItem('userId'); // Clear userId
    localStorage.removeItem('token');
    navigate('/login'); // Redirect to the login page
  };

  const regenerateImage = useCallback(async (index) => {
    try {
      const scene = scenes[index];
      const characterBibleString = generateCharacterDescriptions(scene.additionalInfo.characters, characterBible);
      const newImageURL = await generateImageWithDetails(scene.additionalInfo.visual, scene.additionalInfo.context, characterBibleString, scene.additionalInfo.environment, scene.additionalInfo.characterExperessions);
      const coverImageURL = await saveImageOnCloud(newImageURL, `${currentProject._id}_scene_${scene.number}`);
      const updatedScenes = [...scenes];
      updatedScenes[index] = { ...scene, image: coverImageURL };
      setScenes(updatedScenes);
      saveScenes(currentProject._id, updatedScenes);
    } catch (error) {
      console.error('Error regenerating image:', error);
    }
  }, [scenes, characterBible]);

  const regenerateImagePostEdit = useCallback(async (index, newVisualPrompt) => {
    try {
      const scene = scenes[index];
      const characterBibleString = generateCharacterDescriptions(scene.additionalInfo.characters, characterBible);
      const newImageURL = await generateImageWithDetails(newVisualPrompt, scene.additionalInfo.context, characterBibleString, scene.additionalInfo.environment, scene.additionalInfo.characterExperessions);
      const coverImageURL = await saveImageOnCloud(newImageURL, `${currentProject._id}_scene_${scene.number}`);
      const updatedScenes = [...scenes];
      updatedScenes[index] = {
        ...scene,
        image: coverImageURL,
        additionalInfo: {
          ...scene.additionalInfo,
          visual: newVisualPrompt
        }
      };
      setScenes(updatedScenes);
      saveScenes(currentProject._id, updatedScenes);
    } catch (error) {
      console.error('Error regenerating image:', error);
    }
  }, [scenes, characterBible]);

  const regenerateVideo = useCallback(async (index) => {
    try {
      const scene = scenes[index];
      const newVideoURL = await generateVideoFromPrompt(scene.additionalInfo.visual);
      const videoURL = await saveVideoOnCloud(newVideoURL, `${currentProject._id}_video_${scene.number}`);
      const updatedScenes = [...scenes];
      updatedScenes[index] = { ...scene, video: videoURL };
      setScenes(updatedScenes);
      saveScenes(currentProject._id, updatedScenes);
    } catch (error) {
      console.error('Error regenerating video:', error);
    }
  }, [scenes, characterBible]);

  const regenerateVideoPostEdit = useCallback(async (index, newVisualPrompt) => {
    try {
      const scene = scenes[index];
      const newVideoURL = await generateVideoFromPrompt(newVisualPrompt);
      const updatedScenes = [...scenes];
      updatedScenes[index] = {
        ...scene,
        video: newVideoURL,
        additionalInfo: {
          ...scene.additionalInfo,
          visual: newVisualPrompt
        }
      };
      setScenes(updatedScenes);

    } catch (error) {
      console.error('Error regenerating video:', error);
    }
  }, [scenes, characterBible]);

  const regenerateScreenplay = async () => {
    if (currentProject) {
      setIsScreenplayLoading(true);
      await handleGenerate(currentProject);
    }
  };

  const toggleEditScreenplay = () => {
    setIsEditing(!isEditing);
  };

  const saveEditedScreenplay = async (editedScreenplay) => {
    if (currentProject) {
      await saveScreenplay(currentProject._id, editedScreenplay);
      setDetailedScreenplay(editedScreenplay);
      setIsEditing(false);
    }
  };

  const toggleCharacterSlideshow = () => {
    setIsCharacterSlideshow(!isCharacterSlideshow);
  };

  const toggleMaximizeScreenPlay = () => {
    setMaximizeScreenPlay(!maximizeScreenPlay);
  };

  const handleSaveCharacter = async (updatedCharacter) => {
    const updatedCharacterBible = new Map(characterBible);
    updatedCharacterBible.get(updatedCharacter.name).description = updatedCharacter.details.description;
    setCharacterBible(updatedCharacterBible);
    await saveCharacterBible(currentProject._id, characterBible);
  };

  return (
    <div className="main-container">
      {false ? (
        <div className="header">
        </div>
      ) : null}
      <div className={`overlay ${isOverlayVisible ? 'show' : ''}`} />
      <ProjectSidebar
        projects={projects}
        onSelectProject={handleSelectProject}
        onCreateProject={() => {
          deselectProject();
          setShowNewProjectDialog(true);
        }}
        onRemoveProject={handleRemoveProject} 
        selectedProject={currentProject}
        logout={handleLogout}
      />
      <div className="content-container">
        {showNewProjectDialog && (
          <NewProjectDialog
            onClose={() => setShowNewProjectDialog(false)}
            onCreate={handleCreateProject}
          />
        )}
        {isScreenplayLoading || detailedScreenplay ? (
          <div className="horizontal-screenplay-container">
            <ScreenplayTile
              detailedScreenplay={detailedScreenplay}
              isLoading={isScreenplayLoading}
              onGenerateScenes={handleGenerateScenes}
              isGeneratingScenes={isScenesGenerating}
              isEditing={isEditing}
              onSaveEditedScreenplay={saveEditedScreenplay}
              maximizeScreenPlay={maximizeScreenPlay}
              toggleMaximizeScreenPlay={toggleMaximizeScreenPlay}
              characterBible={characterBible}
            />
            {maximizeScreenPlay ? (
              <div className="vertical-buttons-screenplay-container">
                <button className="toolbar-button" onClick={regenerateScreenplay}>⟳</button>
                <button className="toolbar-button" onClick={toggleEditScreenplay}>✎</button>
                <button className="toolbar-button" onClick={() => { }}>⤓</button>
                <button className="toolbar-button" onClick={toggleCharacterSlideshow}>🪄</button>
              </div>
            ) : null}
          </div>
        ) : null}
        <br />
        {isCharacterBibleLoading || characterBible.size > 0 ? (
          <div className="character-bible-container">

            {maximizeScreenPlay && isCharacterBibleLoading ? (
              <div className="character-bible-header">
                <h2>Generating Character Bible ....</h2>
                <img src={loadingGif} alt="Loading..." className="loading-animation" />
              </div>
            ) : null}

            {maximizeScreenPlay && !isCharacterBibleLoading && characterBible.size > 0 ? (
              <div className="horizontal-character-bible-container">
                <CharacterSlideshow
                  characters={Array.from(characterBible.entries()).map(([name, details]) => ({ name, details }))}
                  setIsOverlayVisible={setIsOverlayVisible}
                  onSaveCharacter={handleSaveCharacter}
                />
              </div>
            ) : null}
          </div>
        ) : null}
        <div className="scenes-container">
          {scenes.map((sceneData, index) => (
            <div className="horizontal-scenes-container" key={index}>
              <SceneTile
                image={sceneData.image}
                title={sceneData.title}
                duration={sceneData.duration}
                summary={sceneData.summary}
                additionalInfo={sceneData.additionalInfo}
                onRegenerateImage={() => regenerateImage(index)}
                onRegenerateImagePostEdit={(newVisualPrompt) => regenerateImagePostEdit(index, newVisualPrompt)}
                onGenerateVideo={() => regenerateVideo(index)}
              />
              {sceneData.video && (
                <VideoTile
                  video={sceneData.video}
                  title={sceneData.title}
                  duration={sceneData.duration}
                  summary={sceneData.summary}
                  additionalInfo={sceneData.additionalInfo}
                  onRegenerateVideo={() => regenerateVideo(index)}
                  onRegenerateVideoPostEdit={(newVisualPrompt) => regenerateVideoPostEdit(index, newVisualPrompt)}
                />
              )}
              <div className="vertical-buttons-container">
                <button className="toolbar-button" onClick={() => regenerateImage(index)}>⟳</button>
                <button className="toolbar-button" onClick={() => { }}>✎</button>
                <button className="toolbar-button" onClick={() => { }}>🔒</button>
                <button className="toolbar-button" onClick={() => { }}>⤓</button>
                <button className="toolbar-button" onClick={() => { }}>💾</button>
                <button className="toolbar-button" onClick={() => { }}>🗑</button>
                <button className="toolbar-button" onClick={() => { }}>👁</button>
                <button className="toolbar-button" onClick={() => { }}>🪄</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MainPage;
